import { collection, doc, query, where, getDocs, updateDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import { format, startOfWeek } from 'date-fns';
import { fr } from 'date-fns/locale';
import React, { useState, useMemo, useEffect } from 'react';
import { Search, ShoppingCart } from 'lucide-react';
import { DragDropContext, DropResult } from '@hello-pangea/dnd';
import { useStore } from '../../store/useStore';
import { useMealPlanStore } from '../../store/useMealPlanStore';
import { useAuthStore } from '../../store/useAuthStore';
import { useCookbookStore } from '../../store/useCookbookStore';
import { Recipe } from '../../types';
import { CalendarView } from './CalendarView';
import { addRecipeToMealPlanAndGrocery } from '../../utils/mealPlanUtils';
import { GroceryListSidebar } from './GroceryListSidebar';
import { AutomationToggle } from './AutomationToggle';
import { useMealPlanManagerStore } from '../../store/useMealPlanManagerStore';
import { ConfirmationDialog } from './ConfirmationDialog';

export function PlanningViewAuto() {
  const [searchTerm, setSearchTerm] = useState('');
  const [isGroceryListOpen, setIsGroceryListOpen] = useState(false);
  const [isAutomationEnabled, setIsAutomationEnabled] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [isUserToggling, setIsUserToggling] = useState(false);
  const LIMIT = 20;

  const recipes = useStore((state) => state.recipes);
  const publicRecipes = useStore((state) => state.publicRecipes);
  const publicRecipeIds = useCookbookStore((state) => state.publicRecipeIds);
  const currentUser = useAuthStore((state) => state.currentUser);
  const { currentMealPlan, createNewMealPlan, updateMealPlan } = useMealPlanManagerStore();
  const [isToggling, setIsToggling] = useState(false);

  // Initialize automation state from meal plan
  useEffect(() => {
    if (currentMealPlan?.automationExpiration) {
      const isAutomated = new Date(currentMealPlan.automationExpiration) > new Date();
      setIsAutomationEnabled(isAutomated);
    } else {
      setIsAutomationEnabled(false);
    }
  }, [currentMealPlan?.automationExpiration]);

  // Get all available recipes
  const allRecipes = useMemo(() => {
    const cookbookRecipes = [
      ...recipes,
      ...publicRecipes.filter((recipe) => publicRecipeIds.includes(recipe.id)),
    ];

    if (!searchTerm) return cookbookRecipes.slice(0, LIMIT);

    const normalizedSearch = searchTerm.toLowerCase();
    return cookbookRecipes.filter((recipe) =>
      recipe.name.toLowerCase().includes(normalizedSearch)
    );
  }, [recipes, publicRecipes, publicRecipeIds, searchTerm]);

  const handleQuickAdd = async (recipe: Recipe) => {
    if (!currentUser?.defaultOrganizationId) return;

    try {
      await addRecipeToMealPlanAndGrocery(
        recipe,
        '', // Empty date for unplanned meals
        '', // Empty meal type for unplanned meals
        currentUser.id,
        currentUser.defaultOrganizationId,
        recipe.servings
      );
    } catch (error) {
      console.error('Error adding recipe:', error);
    }
  };

  const handleAutomationToggle = async () => {
    if (!currentMealPlan || !currentUser?.defaultOrganizationId || isToggling) return;

    setIsUserToggling(true);
    setIsToggling(true);

    try {
      // If turning automation on and there are existing entries, show confirmation
      if (!isAutomationEnabled && currentMealPlan.mealEntries.length > 0) {
        setShowConfirmDialog(true);
        setIsToggling(false);
        return;
      }

      // Set automation expiration to 24 hours from now when enabling
      const automationExpiration = !isAutomationEnabled
        ? new Date(Date.now() + 24 * 60 * 60 * 1000).toISOString()
        : null;

      await updateMealPlan(currentMealPlan.id, {
        automationExpiration,
        updatedAt: new Date(),
        updatedBy: currentUser.id
      });

      setIsAutomationEnabled(!isAutomationEnabled);
    } catch (error) {
      console.error('Error toggling automation:', error);
      setIsAutomationEnabled(isAutomationEnabled); // Revert on error
    } finally {
      setIsToggling(false);
      setIsUserToggling(false);
    }
  };

  const handleCreateNewMealPlan = async () => {
    if (!currentUser?.defaultOrganizationId) return;

    try {
      // Archive current meal plan if it exists
      if (currentMealPlan) {
        await updateMealPlan(currentMealPlan.id, {
          status: 'archived',
          updatedAt: new Date(),
          updatedBy: currentUser.id
        });

        // Archive the grocery list associated with the current meal plan
        const groceryListQuery = query(
          collection(db, 'groceryLists'),
          where('organizationId', '==', currentUser.defaultOrganizationId),
          where('mealPlanId', '==', currentMealPlan.id),
          where('status', '==', 'active')
        );

        const groceryListSnapshot = await getDocs(groceryListQuery);
        if (!groceryListSnapshot.empty) {
          const groceryListId = groceryListSnapshot.docs[0].id;
          await updateDoc(doc(db, 'groceryLists', groceryListId), {
            status: 'archived',
            updatedAt: serverTimestamp(),
          });
        }
      }

      // Create new meal plan with automation enabled
      //const startDate = startOfWeek(new Date(), { locale: fr });
      const startDate = new Date();
      const formattedDate = format(startDate, 'PPPP', { locale: fr });
      const defaultName = `Menu du ${format(startDate, 'd MMMM', { locale: fr })}`;
      
      await createNewMealPlan(defaultName, true); // Pass true to enable automation
      setIsAutomationEnabled(true);
    } catch (error) {
      console.error('Error creating new meal plan:', error);
      throw error;
    } finally {
      setShowConfirmDialog(false);
      setIsUserToggling(false);
    }
  };
  const handleDragEnd = async (result: DropResult) => {
  const { source, destination, draggableId } = result;

  if (!destination || !currentUser?.defaultOrganizationId || !currentMealPlan) return;

  // Prevent dropping into recipe-grid
  if (destination.droppableId === 'recipe-grid') return;

  try {
    if (source.droppableId === 'recipe-grid') {
      // Handle dragging from recipe grid
      const recipe = recipes.find(r => r.id === draggableId);
      if (!recipe) return;

      let destDate = '';
      let destMealType = '';

      if (destination.droppableId !== 'unplanned-recipes') {
        [destDate, destMealType] = destination.droppableId.split('_');
      }

      await addRecipeToMealPlanAndGrocery(
        recipe,
        destDate,
        destMealType,
        currentUser.id,
        currentUser.defaultOrganizationId
      );
    } else {
      // Handle dragging between meal slots
      const entryId = draggableId;
      const entry = currentMealPlan.mealEntries.find(e => e.id === entryId);
      if (!entry) return;

      let newDate = '';
      let newMealType = '';

      if (destination.droppableId !== 'unplanned-recipes') {
        [newDate, newMealType] = destination.droppableId.split('_');
      }

      const updatedEntries = currentMealPlan.mealEntries.map(e =>
        e.id === entryId
          ? { ...e, date: newDate, mealType: newMealType }
          : e
      );

      await updateMealPlan(currentMealPlan.id, {
        mealEntries: updatedEntries,
        updatedAt: new Date()
      });
    }
  } catch (error) {
    console.error('Error handling drag and drop:', error);
  }
};


  const handleConfirmAutomation = async () => {
    if (!currentMealPlan || !currentUser?.defaultOrganizationId) return;

    try {
      // Enable automation on current meal plan
      const automationExpiration = new Date(Date.now() + 24 * 60 * 60 * 1000).toISOString();
      
      await updateMealPlan(currentMealPlan.id, {
        automationExpiration,
        updatedAt: new Date(),
        updatedBy: currentUser.id
      });

      setIsAutomationEnabled(true);
      setShowConfirmDialog(false);
    } catch (error) {
      console.error('Error enabling automation:', error);
    } finally {
      setIsUserToggling(false);
    }
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <div className="relative min-h-screen">
        {/* Main Content */}
        <div className={`transition-all duration-300 ${isGroceryListOpen ? 'mr-80' : ''}`}>
          <div className="space-y-6">
            {/* Header */}
            <div className="flex items-center justify-between gap-4 px-4">
              <div className="relative w-full sm:w-96">
                <Search className="absolute left-3 top-1/2 -translate-y-1/2 h-4 w-4 text-gray-400" />
                <input
                  type="search"
                  placeholder="Rechercher une recette..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-menioo-green focus:border-menioo-green"
                />
              </div>
              {/* Automation Toggle */}
              <div className="flex items-center space-x-4">
                <AutomationToggle 
                  isEnabled={isAutomationEnabled}
                  onToggle={handleAutomationToggle}
                />
              </div>
            </div>

            {/* Calendar View */}
            <CalendarView 
              recipes={allRecipes} 
              onQuickAdd={handleQuickAdd}
              isAutomationEnabled={isAutomationEnabled}
              onOpenGroceryList={() => setIsGroceryListOpen(true)}
            />
          </div>
        </div>

        {/* Sidebar */}
        <div 
          className={`fixed top-0 right-0 h-screen w-80 bg-white shadow-lg transform transition-transform duration-300 ease-in-out z-[70] ${
            isGroceryListOpen ? 'translate-x-0' : 'translate-x-full'
          }`}
        >
          <GroceryListSidebar />
        </div>

        {/* Sidebar Toggle Button */}
        <button
          onClick={() => setIsGroceryListOpen(!isGroceryListOpen)}
          className={`fixed top-24 transition-all duration-300 z-[60] ${
            isGroceryListOpen ? 'right-80' : 'right-0'
          } p-2 rounded-l-lg bg-white shadow-md border border-r-0 border-gray-200 hover:bg-gray-50`}
          aria-label={isGroceryListOpen ? 'Hide grocery list' : 'Show grocery list'}
        >
          <ShoppingCart className="h-5 w-5 text-menioo-navy" />
        </button>

        {/* Confirmation Dialog */}
        <ConfirmationDialog
          isOpen={showConfirmDialog}
          onClose={() => {
            setShowConfirmDialog(false);
            setIsUserToggling(false);
          }}
          onConfirm={handleConfirmAutomation}
          onCancel={handleCreateNewMealPlan}
        />
      </div>
    </DragDropContext>
  );
}