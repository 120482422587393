import React, { useState } from 'react';
import { Heart } from 'lucide-react';
import { doc, increment, updateDoc, getDoc, setDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import { useAuthStore } from '../../store/useAuthStore';

interface HeartButtonProps {
  recipeId: string;
  isActive: boolean;
  onClick: (e: React.MouseEvent) => void;
  className?: string;
  initialCount?: number;
}

export function HeartButton({ recipeId, isActive, onClick, className = '', initialCount = 0 }: HeartButtonProps) {
  const [isAnimating, setIsAnimating] = useState(false);
  const [bookmarkCount, setBookmarkCount] = useState(initialCount);
  const currentUser = useAuthStore((state) => state.currentUser);

  const handleClick = async (e: React.MouseEvent) => {
    // Stop event propagation to prevent navigation
    e.preventDefault();
    e.stopPropagation();
    
    if (!currentUser?.defaultOrganizationId) return;
    
    setIsAnimating(true);
    
    try {
      // Update the bookmark count in Firestore
      const recipeRef = doc(db, 'recipes', recipeId);
      await updateDoc(recipeRef, {
        bookmarkCount: increment(isActive ? -1 : 1)
      });

      // Check if cookbook exists and create if needed
      const cookbookRef = doc(db, 'cookbooks', currentUser.defaultOrganizationId);
      const cookbookDoc = await getDoc(cookbookRef);

      if (!cookbookDoc.exists()) {
        // Create new cookbook
        await setDoc(cookbookRef, {
          organizationId: currentUser.defaultOrganizationId,
          publicRecipeIds: isActive ? [] : [recipeId],
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp()
        });
      }

      // Update local state
      setBookmarkCount(prev => prev + (isActive ? -1 : 1));
      
      // Call the original onClick handler
      onClick(e);
    } catch (error) {
      console.error('Error updating bookmark count:', error);
    } finally {
      setTimeout(() => setIsAnimating(false), 300);
    }
  };

  return (
    <button
      onClick={handleClick}
      className={`group p-2 bg-white/90 backdrop-blur-sm rounded-full shadow-md hover:bg-white 
        transition-all duration-300 ${className}`}
    >
      <Heart 
        fill={isActive ? '#ef4444' : 'none'}
        className={`h-5 w-5 transition-all duration-300 transform
          ${isActive ? 'stroke-red-500' : 'stroke-gray-400 group-hover:stroke-gray-600'}
          ${isAnimating 
            ? 'scale-125' 
            : isActive 
              ? 'scale-110' 
              : 'scale-100 group-hover:scale-105'
          }`}
        style={{
          strokeWidth: isActive ? 2.5 : 2,
          animation: isAnimating ? 'heartBeat 0.3s ease-in-out' : 'none'
        }}
      />
    </button>
  );
}