import React, { useState } from 'react';
import { Plus } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { IngredientSelector } from '../recipe/IngredientSelector';
import { StandardIngredient } from '../../types/ingredients';
import { standardUnits } from '../../types/units';
import { GroceryItemSelector } from './GroceryItemSelector';
import { useGroceryListStore } from '../../store/useGroceryListStore';
import { useAuthStore } from '../../store/useAuthStore';
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import { format, startOfWeek, endOfWeek } from 'date-fns';
import { fr } from 'date-fns/locale';

interface AddCustomItemProps {
  onAdd: (item: {
    name: string;
    quantity: number;
    unit: string;
    category: string;
    standardIngredientId?: string;
  }) => Promise<void>;
}

export function AddCustomItem({ onAdd }: AddCustomItemProps) {
  const { t } = useTranslation();
  const [isAdding, setIsAdding] = useState(false);
  const [quantity, setQuantity] = useState('1');
  const [selectedIngredient, setSelectedIngredient] = useState<StandardIngredient | null>(null);
  const [selectedUnit, setSelectedUnit] = useState('piece');
  const currentUser = useAuthStore((state) => state.currentUser);
  const { currentList } = useGroceryListStore();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!selectedIngredient?.name || !currentUser?.defaultOrganizationId) return;

    try {
      // Create a clean object without any undefined values
    const itemData = {
      name: selectedIngredient.name,
      quantity: Math.max(1, Math.round(Number(quantity))),
      unit: selectedUnit || 'piece',
      category: selectedIngredient.category || 'other',
      standardIngredientId: selectedIngredient.id || ''
    };

      // If no active list exists, create a new one
      if (!currentList) {
        const startDate = startOfWeek(new Date(), { locale: fr });
        const endDate = endOfWeek(new Date(), { locale: fr });

        const newList = {
          name: `Liste de courses du ${format(startDate, 'd MMMM', { locale: fr })}`,
          organizationId: currentUser.defaultOrganizationId,
          status: 'active',
          items: [],
          startDate: format(startDate, 'yyyy-MM-dd'),
          endDate: format(endDate, 'yyyy-MM-dd'),
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp()
        };

        await addDoc(collection(db, 'groceryLists'), newList);
      }

      await onAdd(itemData);
      
      // Reset form
      setQuantity('1');
      setSelectedIngredient(null);
      setSelectedUnit('piece');
      setIsAdding(false);
    } catch (error) {
      console.error('Error adding custom item:', error);
    }
  };

  if (!isAdding) {
    return (
      <button
        onClick={() => setIsAdding(true)}
        className="flex items-center space-x-2 text-menioo-green hover:text-menioo-green-dark transition-colors"
      >
        <Plus className="h-4 w-4" />
        <span>Ajouter un article</span>
      </button>
    );
  }

  return (
    <form onSubmit={handleSubmit} className="bg-white p-4 rounded-lg shadow-sm border border-gray-100">
      <div className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-menioo-navy mb-1">
            Article
          </label>
           <GroceryItemSelector
          value={{ name: selectedIngredient?.name || '', unit: selectedUnit || selectedIngredient?.preferredUnit || '' }}
          onChange={(ingredient) => {
            setSelectedIngredient(ingredient);
            setSelectedUnit(ingredient.preferredUnit);
          }}
          className="w-full"
        />
        </div>
        
        <div className="grid grid-cols-2 gap-4">
          <div>
            <label className="block text-sm font-medium text-menioo-navy mb-1">
              Quantité
            </label>
            <input
              type="number"
              value={quantity}
              onChange={(e) => setQuantity(e.target.value)}
              required
              min="1"
              className="w-full rounded-md border-gray-300 shadow-sm focus:border-menioo-green focus:ring-menioo-green sm:text-sm"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-menioo-navy mb-1">
              Unité
            </label>
            <select
              value={selectedUnit}
              onChange={(e) => setSelectedUnit(e.target.value)}
              className="w-full rounded-md border-gray-300 shadow-sm focus:border-menioo-green focus:ring-menioo-green sm:text-sm"
            >
              {Object.entries(standardUnits).map(([unit, translation]) => (
                <option key={unit} value={unit}>
                  {t(`units.${unit}`)}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>

      <div className="mt-4 flex justify-end space-x-2">
        <button
          type="button"
          onClick={() => setIsAdding(false)}
          className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
        >
          Annuler
        </button>
        <button
          type="submit"
          disabled={!selectedIngredient}
          className="px-4 py-2 text-sm font-medium text-white bg-menioo-green rounded-md hover:bg-menioo-green-dark disabled:opacity-50"
        >
          Ajouter
        </button>
      </div>
    </form>
  );
}