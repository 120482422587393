import { create } from 'zustand';
import { doc, collection, query, where, getDocs, updateDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../lib/firebase';
import type { Organization, OrganizationRole } from '../types/organization';

interface OrganizationStore {
  organizations: Record<string, Organization>;
  isLoading: boolean;
  error: string | null;
  fetchOrganizations: (userOrgIds: string[]) => Promise<void>;
  updateOrganization: (orgId: string, updates: Partial<Organization>) => Promise<void>;
  updateMemberRole: (orgId: string, userId: string, newRole: OrganizationRole) => Promise<void>;
  removeMember: (orgId: string, userId: string) => Promise<void>;
}

export const useOrganizationStore = create<OrganizationStore>((set, get) => ({
  organizations: {},
  isLoading: false,
  error: null,

  fetchOrganizations: async (userOrgIds) => {
    set({ isLoading: true, error: null });
    try {
      const orgsData: Record<string, Organization> = {};
      
      for (const orgId of userOrgIds) {
        const orgDoc = await getDocs(doc(db, 'organizations', orgId));
        if (orgDoc.exists()) {
          orgsData[orgId] = { id: orgId, ...orgDoc.data() } as Organization;
        }
      }
      
      set({ organizations: orgsData });
    } catch (error) {
      console.error('Error fetching organizations:', error);
      set({ error: (error as Error).message });
    } finally {
      set({ isLoading: false });
    }
  },

  updateOrganization: async (orgId, updates) => {
    try {
      await updateDoc(doc(db, 'organizations', orgId), {
        ...updates,
        updatedAt: serverTimestamp()
      });
      
      set(state => ({
        organizations: {
          ...state.organizations,
          [orgId]: {
            ...state.organizations[orgId],
            ...updates
          }
        }
      }));
    } catch (error) {
      console.error('Error updating organization:', error);
      throw error;
    }
  },

  updateMemberRole: async (orgId, userId, newRole) => {
    const { organizations } = get();
    const org = organizations[orgId];
    
    if (!org) throw new Error('Organization not found');
    
    const updatedMembers = org.members.map(member =>
      member.userId === userId ? { ...member, role: newRole } : member
    );
    
    await get().updateOrganization(orgId, { members: updatedMembers });
  },

  removeMember: async (orgId, userId) => {
    const { organizations } = get();
    const org = organizations[orgId];
    
    if (!org) throw new Error('Organization not found');
    
    const updatedMembers = org.members.filter(member => member.userId !== userId);
    
    await get().updateOrganization(orgId, { 
      members: updatedMembers,
      numberOfPersons: updatedMembers.length
    });
  }
}));
