import React from 'react';
import { DISH_TYPES, DIETARY_RESTRICTIONS, DIFFICULTIES, COSTS } from '../../types/recipe';
import type { RecipeClassification } from '../../types/recipe';

interface RecipeClassificationFormProps {
  value: RecipeClassification;
  onChange: (updates: Partial<RecipeClassification>) => void;
}

export function RecipeClassificationForm({ value, onChange }: RecipeClassificationFormProps) {
  return (
    <div className="space-y-6">
      {/* Dish Types (Multiple Select) */}
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Type de plat
        </label>
        <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-2">
          {DISH_TYPES.map(({ value: type, label }) => (
            <label key={type} className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={value.dishTypes.includes(type)}
                onChange={(e) => {
                  const newTypes = e.target.checked
                    ? [...value.dishTypes, type]
                    : value.dishTypes.filter(t => t !== type);
                  onChange({ dishTypes: newTypes });
                }}
                className="rounded border-gray-300 text-menioo-green focus:ring-menioo-green"
              />
              <span className="text-sm text-gray-700">{label}</span>
            </label>
          ))}
        </div>
      </div>

      {/* Dietary Restrictions (Multiple Select) */}
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Régime alimentaire
        </label>
        <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-2">
          {DIETARY_RESTRICTIONS.map(({ value: restriction, label }) => (
            <label key={restriction} className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={value.dietaryRestrictions.includes(restriction)}
                onChange={(e) => {
                  const newRestrictions = e.target.checked
                    ? [...value.dietaryRestrictions, restriction]
                    : value.dietaryRestrictions.filter(r => r !== restriction);
                  onChange({ dietaryRestrictions: newRestrictions });
                }}
                className="rounded border-gray-300 text-menioo-green focus:ring-menioo-green"
              />
              <span className="text-sm text-gray-700">{label}</span>
            </label>
          ))}
        </div>
      </div>

      <div className="grid grid-cols-2 gap-6">
        {/* Difficulty (Single Select) */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Difficulté
          </label>
          <select
            value={value.difficulty}
            onChange={(e) => onChange({ difficulty: e.target.value as any })}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-menioo-green focus:ring-menioo-green sm:text-sm"
          >
            {DIFFICULTIES.map(({ value: difficulty, label }) => (
              <option key={difficulty} value={difficulty}>
                {label}
              </option>
            ))}
          </select>
        </div>

        {/* Cost (Single Select) */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Coût
          </label>
          <select
            value={value.cost}
            onChange={(e) => onChange({ cost: e.target.value as any })}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-menioo-green focus:ring-menioo-green sm:text-sm"
          >
            {COSTS.map(({ value: cost, label }) => (
              <option key={cost} value={cost}>
                {label}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
}