// src/types/recipe.ts

export type DishType = 
  | 'aperitif'
  | 'starter'
  | 'main'
  | 'dessert'
  | 'drink' 
  | 'side'
  | 'sharing';

export type DietaryRestriction =
  | 'vegetarian'
  | 'vegan'
  | 'glutenFree'
  | 'lactoseFree';

export type Difficulty = 'easy' | 'medium' | 'hard';

export type Cost = 'cheap' | 'medium' | 'expensive';

export interface RecipeClassification {
  dishTypes: DishType[];
  dietaryRestrictions: DietaryRestriction[];
  difficulty: Difficulty;
  cost: Cost;
}

export const DISH_TYPES: { value: DishType; label: string }[] = [
  { value: 'aperitif', label: 'Apéritif' },
  { value: 'starter', label: 'Entrée' },
  { value: 'main', label: 'Plat principal' },
  { value: 'dessert', label: 'Dessert' },
  { value: 'drink', label: 'Boisson' },
  { value: 'side', label: 'Accompagnement' },
  { value: 'sharing', label: 'Convivial' }
];

export const DIETARY_RESTRICTIONS: { value: DietaryRestriction; label: string }[] = [
  { value: 'vegetarian', label: 'Végétarien' },
  { value: 'vegan', label: 'Végan' },
  { value: 'glutenFree', label: 'Sans gluten' },
  { value: 'lactoseFree', label: 'Sans lactose' }
];

export const DIFFICULTIES: { value: Difficulty; label: string }[] = [
  { value: 'easy', label: 'Facile' },
  { value: 'medium', label: 'Moyen' },
  { value: 'hard', label: 'Difficile' }
];

export const COSTS: { value: Cost; label: string }[] = [
  { value: 'cheap', label: 'Économique' },
  { value: 'medium', label: 'Moyen' },
  { value: 'expensive', label: 'Cher' }
];
