import { create } from 'zustand';
import { 
  collection,
  doc,
  addDoc,
  deleteDoc,
  query,
  where,
  onSnapshot,
  updateDoc,
  getDocs,
  writeBatch,
  serverTimestamp,
  runTransaction,
  orderBy
} from 'firebase/firestore';
import { db } from '../lib/firebase';
import { Recipe, MealPlan, GroceryItem, Bookmark } from '../types';
import { useAuthStore } from './useAuthStore';

interface Store {
  recipes: Recipe[];
  publicRecipes: Recipe[];
  mealPlan: MealPlan[];
  groceryList: GroceryItem[];
  bookmarks: Bookmark[];
  isLoading: boolean;
  error: string | null;
  fetchPublicRecipes: () => Promise<void>;
  addRecipe: (recipe: Omit<Recipe, 'id' | 'organizationId'>) => Promise<void>;
  updateRecipe: (id: string, recipe: Partial<Recipe>) => Promise<void>;
  removeRecipe: (id: string) => Promise<void>;
  addToMealPlan: (mealPlan: Omit<MealPlan, 'id'>) => Promise<void>;
  removeFromMealPlan: (id: string) => Promise<void>;
  updateGroceryList: () => Promise<void>;
  toggleGroceryItem: (id: string) => Promise<void>;
  clearGroceryList: () => Promise<void>;
  bookmarkRecipe: (recipeId: string) => Promise<void>;
  removeBookmark: (bookmarkId: string) => Promise<void>;
  initializeSubscriptions: () => void;
  cleanup: () => void;
}

export const useStore = create<Store>((set, get) => {
  let recipesUnsubscribe: (() => void) | null = null;
  let mealPlansUnsubscribe: (() => void) | null = null;
  let groceryListUnsubscribe: (() => void) | null = null;
  let bookmarksUnsubscribe: (() => void) | null = null;

  return {
    recipes: [],
    publicRecipes: [],
    mealPlan: [],
    groceryList: [],
    bookmarks: [],
    isLoading: false,
    error: null,

    fetchPublicRecipes: async () => {
      try {
        const publicRecipesQuery = query(
          collection(db, 'recipes'),
          where('isPublic', '==', true),
          orderBy('createdAt', 'desc')
        );
        
        const snapshot = await getDocs(publicRecipesQuery);
        const recipes: Recipe[] = [];
        snapshot.forEach((doc) => {
          recipes.push({ id: doc.id, ...doc.data() } as Recipe);
        });
        
        set({ publicRecipes: recipes });
      } catch (error) {
        console.error('Error fetching public recipes:', error);
        set({ error: (error as Error).message });
      }
    },

addRecipe: async (recipe) => {
  const currentUser = useAuthStore.getState().currentUser;
  if (!currentUser?.defaultOrganizationId) return;

  set({ isLoading: true, error: null });

  try {
    await addDoc(collection(db, 'recipes'), {
      ...recipe,
      bookmarkCount: 0, // Initialize bookmark count
      organizationId: currentUser.defaultOrganizationId,
      createdBy: {
        id: currentUser.id,
        name: currentUser.name || `${currentUser.firstName} ${currentUser.lastName}`.trim()
      },
      createdAt: new Date().toISOString(), // Use ISO 8601 format
      updatedAt: new Date().toISOString()  // Use ISO 8601 format
    });
  } catch (error) {
    console.error('Error adding recipe:', error);
    set({ error: (error as Error).message });
  } finally {
    set({ isLoading: false });
  }
},

    updateRecipe: async (id, recipe) => {
      set({ isLoading: true, error: null });

      try {
        await updateDoc(doc(db, 'recipes', id), {
          ...recipe,
          updatedAt: serverTimestamp()
        });
      } catch (error) {
        console.error('Error updating recipe:', error);
        set({ error: (error as Error).message });
      } finally {
        set({ isLoading: false });
      }
    },

    removeRecipe: async (id) => {
      set({ isLoading: true, error: null });

      try {
        await deleteDoc(doc(db, 'recipes', id));
      } catch (error) {
        console.error('Error removing recipe:', error);
        set({ error: (error as Error).message });
      } finally {
        set({ isLoading: false });
      }
    },

    addToMealPlan: async (mealPlan) => {
      set({ isLoading: true, error: null });

      try {
        await addDoc(collection(db, 'mealPlans'), {
          ...mealPlan,
          createdAt: serverTimestamp()
        });
      } catch (error) {
        console.error('Error adding to meal plan:', error);
        set({ error: (error as Error).message });
      } finally {
        set({ isLoading: false });
      }
    },

    removeFromMealPlan: async (id) => {
      set({ isLoading: true, error: null });

      try {
        await deleteDoc(doc(db, 'mealPlans', id));
      } catch (error) {
        console.error('Error removing from meal plan:', error);
        set({ error: (error as Error).message });
      } finally {
        set({ isLoading: false });
      }
    },

    updateGroceryList: async () => {
      const currentUser = useAuthStore.getState().currentUser;
      if (!currentUser?.defaultOrganizationId) return;

      set({ isLoading: true, error: null });

      try {
        const { mealPlan, recipes } = get();
        const plannedRecipes = mealPlan
          .map(meal => recipes.find(recipe => recipe.id === meal.recipeId))
          .filter((recipe): recipe is Recipe => recipe !== undefined);

        const groupedIngredients = new Map<string, GroceryItem>();

        plannedRecipes.forEach(recipe => {
          recipe.ingredients.forEach(ingredient => {
            const key = `${ingredient.name.toLowerCase()}-${ingredient.unit}`;
            
            if (groupedIngredients.has(key)) {
              const existingItem = groupedIngredients.get(key)!;
              existingItem.quantity += ingredient.quantity;
            } else {
              const newItem: GroceryItem = {
                id: crypto.randomUUID(),
                ...ingredient,
                checked: false,
                userId: currentUser.id,
                organizationId: currentUser.defaultOrganizationId,
                createdAt: new Date().toISOString(),
                updatedAt: new Date().toISOString()
              };
              groupedIngredients.set(key, newItem);
            }
          });
        });

        const batch = writeBatch(db);

        const existingItemsQuery = query(
          collection(db, 'groceryLists'),
          where('organizationId', '==', currentUser.defaultOrganizationId)
        );
        const existingItems = await getDocs(existingItemsQuery);
        existingItems.forEach(doc => {
          batch.delete(doc.ref);
        });

        const sortedItems = Array.from(groupedIngredients.values()).sort((a, b) => {
          if (a.category === b.category) {
            return a.name.localeCompare(b.name);
          }
          return a.category.localeCompare(b.category);
        });

        sortedItems.forEach(item => {
          const docRef = doc(collection(db, 'groceryLists'));
          batch.set(docRef, {
            ...item,
            id: docRef.id
          });
        });

        await batch.commit();
        set({ groceryList: sortedItems, error: null });
      } catch (error) {
        console.error('Error updating grocery list:', error);
        set({ error: (error as Error).message });
      } finally {
        set({ isLoading: false });
      }
    },

    toggleGroceryItem: async (id) => {
      const currentUser = useAuthStore.getState().currentUser;
      if (!currentUser?.defaultOrganizationId) return;

      set({ error: null });

      try {
        const itemRef = doc(db, 'groceryLists', id);
        await runTransaction(db, async (transaction) => {
          const itemDoc = await transaction.get(itemRef);
          if (itemDoc.exists()) {
            const item = itemDoc.data() as GroceryItem;
            transaction.update(itemRef, {
              checked: !item.checked,
              updatedAt: serverTimestamp()
            });
            
            set(state => ({
              groceryList: state.groceryList.map(groceryItem =>
                groceryItem.id === id
                  ? { ...groceryItem, checked: !groceryItem.checked }
                  : groceryItem
              )
            }));
          }
        });
      } catch (error) {
        console.error('Error toggling grocery item:', error);
        set({ error: (error as Error).message });
      }
    },

    clearGroceryList: async () => {
      const currentUser = useAuthStore.getState().currentUser;
      if (!currentUser?.defaultOrganizationId) return;

      set({ isLoading: true, error: null });

      try {
        const batch = writeBatch(db);
        const groceryListQuery = query(
          collection(db, 'groceryLists'),
          where('organizationId', '==', currentUser.defaultOrganizationId)
        );
        const snapshot = await getDocs(groceryListQuery);
        
        snapshot.docs.forEach((doc) => {
          batch.delete(doc.ref);
        });

        await batch.commit();
        set({ groceryList: [], error: null });
      } catch (error) {
        console.error('Error clearing grocery list:', error);
        set({ error: (error as Error).message });
      } finally {
        set({ isLoading: false });
      }
    },

    bookmarkRecipe: async (recipeId) => {
      const currentUser = useAuthStore.getState().currentUser;
      if (!currentUser?.defaultOrganizationId) return;

      set({ error: null });

      try {
        await addDoc(collection(db, 'bookmarks'), {
          userId: currentUser.id,
          recipeId,
          createdAt: serverTimestamp()
        });
      } catch (error) {
        console.error('Error bookmarking recipe:', error);
        set({ error: (error as Error).message });
      }
    },

    removeBookmark: async (bookmarkId) => {
      set({ error: null });

      try {
        await deleteDoc(doc(db, 'bookmarks', bookmarkId));
      } catch (error) {
        console.error('Error removing bookmark:', error);
        set({ error: (error as Error).message });
      }
    },

    initializeSubscriptions: () => {
      const currentUser = useAuthStore.getState().currentUser;
      if (!currentUser?.defaultOrganizationId) {
        console.error('No default organization ID found');
        return;
      }

      set({ isLoading: true, error: null });

      try {
        const recipesQuery = query(
          collection(db, 'recipes'),
          where('organizationId', '==', currentUser.defaultOrganizationId),
          orderBy('createdAt', 'desc')
        );

        recipesUnsubscribe = onSnapshot(recipesQuery, 
          (snapshot) => {
            const recipes: Recipe[] = [];
            snapshot.forEach((doc) => {
              recipes.push({ id: doc.id, ...doc.data() } as Recipe);
            });
            set({ recipes, error: null });
          },
          (error) => {
            console.error('Error in recipes subscription:', error);
            set({ error: error.message });
          }
        );

        const mealPlansQuery = query(
          collection(db, 'mealPlans'),
          where('organizationId', '==', currentUser.defaultOrganizationId),
          orderBy('createdAt', 'desc')
        );

        mealPlansUnsubscribe = onSnapshot(mealPlansQuery, 
          (snapshot) => {
            const mealPlans: MealPlan[] = [];
            snapshot.forEach((doc) => {
              mealPlans.push({ id: doc.id, ...doc.data() } as MealPlan);
            });
            set({ mealPlan: mealPlans, error: null });
          },
          (error) => {
            console.error('Error in meal plans subscription:', error);
            set({ error: error.message });
          }
        );

        const groceryListQuery = query(
          collection(db, 'groceryLists'),
          where('organizationId', '==', currentUser.defaultOrganizationId),
          orderBy('createdAt', 'desc')
        );

        groceryListUnsubscribe = onSnapshot(groceryListQuery,
          (snapshot) => {
            const groceryItems: GroceryItem[] = [];
            snapshot.forEach((doc) => {
              groceryItems.push({ id: doc.id, ...doc.data() } as GroceryItem);
            });
            set({ groceryList: groceryItems, error: null });
          },
          (error) => {
            console.error('Error in grocery list subscription:', error);
            set({ error: error.message });
          }
        );

        const bookmarksQuery = query(
          collection(db, 'bookmarks'),
          where('userId', '==', currentUser.id),
          orderBy('createdAt', 'desc')
        );

        bookmarksUnsubscribe = onSnapshot(bookmarksQuery,
          (snapshot) => {
            const bookmarks: Bookmark[] = [];
            snapshot.forEach((doc) => {
              bookmarks.push({ id: doc.id, ...doc.data() } as Bookmark);
            });
            set({ bookmarks, error: null });
          },
          (error) => {
            console.error('Error in bookmarks subscription:', error);
            set({ error: error.message });
          }
        );

      } catch (error) {
        console.error('Error initializing subscriptions:', error);
        set({ error: (error as Error).message });
      } finally {
        set({ isLoading: false });
      }
    },

    cleanup: () => {
      if (recipesUnsubscribe) recipesUnsubscribe();
      if (mealPlansUnsubscribe) mealPlansUnsubscribe();
      if (groceryListUnsubscribe) groceryListUnsubscribe();
      if (bookmarksUnsubscribe) bookmarksUnsubscribe();
      
      set({
        recipes: [],
        mealPlan: [],
        groceryList: [],
        bookmarks: [],
        error: null
      });
    }
  };
});