import { create } from 'zustand';
import { 
  createUserWithEmailAndPassword, 
  signInWithEmailAndPassword, 
  signInWithPopup, 
  signOut, 
  setPersistence, 
  browserLocalPersistence, 
  sendEmailVerification, 
  sendPasswordResetEmail, 
  GoogleAuthProvider 
} from 'firebase/auth';
import { 
  doc, 
  setDoc, 
  getDoc, 
  updateDoc, 
  serverTimestamp,
  collection,
  query,
  where,
  getDocs,
  setDoc,
  runTransaction 
} from 'firebase/firestore';
import { auth, db } from '../lib/firebase';
import type { User, Organization, UserRole, OrganizationMember } from '../types';
import { sendOrganizationInvite } from '../lib/mailjet';


interface AuthState {
  currentUser: User | null;
  currentOrganization: Organization | null;
  isAuthenticated: boolean;
  error: string | null;
  isLoading: boolean;
}

interface AuthStore extends AuthState {
  setAuthState: (state: Partial<AuthState>) => void;
  login: (email: string, password: string) => Promise<void>;
  signup: (email: string, password: string, firstName: string, lastName: string, organizationName: string) => Promise<void>;
  signInWithGoogle: () => Promise<void>;
  logout: () => Promise<void>;
  switchOrganization: (organizationId: string) => Promise<void>;
  updateOrganization: (updates: Partial<Organization>) => Promise<void>;
  addUserToOrganization: (email: string, role: UserRole) => Promise<void>;
  removeUserFromOrganization: (userId: string) => Promise<void>;
  updateUserRole: (userId: string, role: UserRole) => Promise<void>;
  setDefaultOrganization: (organizationId: string) => Promise<void>;
  sendPasswordReset: (email: string) => Promise<void>;
  fetchOrganization: (orgId: string) => Promise<Organization>;

  clearError: () => void;
}

export const useAuthStore = create<AuthStore>((set, get) => ({
  currentUser: null,
  currentOrganization: null,
  isAuthenticated: false,
  error: null,
  isLoading: false,

  setAuthState: (state) => set(state),

  clearError: () => set({ error: null }),

  sendPasswordReset: async (email: string) => {
    set({ isLoading: true, error: null });
    try {
      await sendPasswordResetEmail(auth, email);
    } catch (error) {
      console.error('Password reset error:', error);
      set({ error: 'Erreur lors de l\'envoi de l\'email. Veuillez réessayer.' });
      throw error;
    } finally {
      set({ isLoading: false });
    }
  },

  login: async (email: string, password: string) => {
    set({ isLoading: true, error: null });

    try {
      await setPersistence(auth, browserLocalPersistence);
      const { user: firebaseUser } = await signInWithEmailAndPassword(auth, email, password);

      const userDoc = await getDoc(doc(db, 'users', firebaseUser.uid));
      if (!userDoc.exists()) {
        await signOut(auth);
        set({
          error: 'Compte utilisateur introuvable. Veuillez vous inscrire.',
          isLoading: false,
          currentUser: null,
          currentOrganization: null,
          isAuthenticated: false,
        });
        return;
      }

      const userData = userDoc.data() as User;
      if (!userData.emailVerified) {
        await signOut(auth);
        set({
          error: 'Veuillez vérifier votre email avant de vous connecter.',
          isLoading: false,
          currentUser: null,
          currentOrganization: null,
          isAuthenticated: false,
        });
        return;
      }

      const defaultOrgId = userData.defaultOrganizationId;
      const orgDoc = await getDoc(doc(db, 'organizations', defaultOrgId));
      if (!orgDoc.exists()) {
        await signOut(auth);
        set({
          error: 'Organisation introuvable. Veuillez contacter le support.',
          isLoading: false,
          currentUser: null,
          currentOrganization: null,
          isAuthenticated: false,
        });
        return;
      }

      const orgData = orgDoc.data() as Organization;
      set({
        currentUser: { ...userData, id: userDoc.id },
        currentOrganization: { ...orgData, id: orgDoc.id },
        isAuthenticated: true,
        error: null,
        isLoading: false,
      });
    } catch (error: any) {
      console.error('Login error:', error);
      let errorMessage = 'Email ou mot de passe incorrect.';
      if (error.code === 'auth/too-many-requests') {
        errorMessage = 'Trop de tentatives échouées. Veuillez réessayer plus tard.';
      }
      set({
        error: errorMessage,
        isLoading: false,
        currentUser: null,
        currentOrganization: null,
        isAuthenticated: false,
      });
    }
  },

  signup: async (email, password, firstName, lastName, organizationName) => {
    set({ isLoading: true, error: null });

    try {
      await setPersistence(auth, browserLocalPersistence);
      const { user: firebaseUser } = await createUserWithEmailAndPassword(auth, email, password);

      const timestamp = new Date().toISOString();
      const orgId = crypto.randomUUID();

      const user: User = {
        id: firebaseUser.uid,
        email,
        firstName,
        lastName,
        emailVerified: false,
        defaultOrganizationId: orgId,
        organizations: {
          [orgId]: {
            organizationId: orgId,
            role: 'owner',
            joinedAt: timestamp,
          },
        },
        createdAt: timestamp,
        updatedAt: timestamp,
      };

      const organization: Organization = {
        id: orgId,
        name: organizationName,
        creatorId: firebaseUser.uid,
        members: [
          {
            userId: firebaseUser.uid,
            email,
            name: `${firstName} ${lastName}`.trim(),
            role: 'owner',
            invitationAccepted: true,
            joinedAt: timestamp,
          },
        ],
        numberOfPersons: 1,
        createdAt: timestamp,
        updatedAt: timestamp,
      };

      await runTransaction(db, async (transaction) => {
        transaction.set(doc(db, 'users', firebaseUser.uid), user);
        transaction.set(doc(db, 'organizations', orgId), organization);
      });

      await sendEmailVerification(firebaseUser, {
        url: `${window.location.origin}/verify-email?uid=${firebaseUser.uid}`,
        handleCodeInApp: true,
      });

      await signOut(auth);
      set({
        error: null,
        currentUser: null,
        currentOrganization: null,
        isAuthenticated: false,
        isLoading: false,
      });
    } catch (error: any) {
      console.error('Signup error:', error);
      let errorMessage = 'Erreur lors de la création du compte. Veuillez réessayer.';
      if (error.code === 'auth/email-already-in-use') {
        errorMessage = 'Cet email est déjà utilisé. Veuillez vous connecter.';
      }
      set({
        error: errorMessage,
        isLoading: false,
        currentUser: null,
        currentOrganization: null,
        isAuthenticated: false,
      });
    }
  },

  signInWithGoogle: async () => {
  set({ isLoading: true, error: null });

  try {
    const provider = new GoogleAuthProvider();
    const result = await signInWithPopup(auth, provider);
    const firebaseUser = result.user;

    let userDoc = await getDoc(doc(db, 'users', firebaseUser.uid));

    if (!userDoc.exists()) {
      // If the user is signing in for the first time, create user and organization
      const timestamp = new Date().toISOString();
      const orgId = crypto.randomUUID();

      const newUser: User = {
        id: firebaseUser.uid,
        email: firebaseUser.email || '',
        firstName: firebaseUser.displayName?.split(' ')[0] || '',
        lastName: firebaseUser.displayName?.split(' ')[1] || '',
        emailVerified: firebaseUser.emailVerified,
        defaultOrganizationId: orgId,
        organizations: {
          [orgId]: {
            organizationId: orgId,
            role: 'owner',
            joinedAt: timestamp,
          },
        },
        createdAt: timestamp,
        updatedAt: timestamp,
      };

      const newOrganization: Organization = {
        id: orgId,
        name: `${newUser.firstName || 'Nouvelle'} Organisation`,
        creatorId: firebaseUser.uid,
        members: [
          {
            userId: firebaseUser.uid,
            email: newUser.email,
            name: `${newUser.firstName} ${newUser.lastName}`.trim(),
            role: 'owner',
            invitationAccepted: true,
            joinedAt: timestamp,
          },
        ],
        numberOfPersons: 1,
        createdAt: timestamp,
        updatedAt: timestamp,
      };

      await runTransaction(db, async (transaction) => {
        transaction.set(doc(db, 'users', firebaseUser.uid), newUser);
        transaction.set(doc(db, 'organizations', orgId), newOrganization);
      });

      userDoc = await getDoc(doc(db, 'users', firebaseUser.uid));
    }

    const userData = userDoc.data() as User;

    // Update the store state with the new user
    set({
      currentUser: userData,
      currentOrganization: userData.defaultOrganizationId
        ? { id: userData.defaultOrganizationId }
        : null,
      isAuthenticated: true,
      isLoading: false,
      error: null,
    });
  } catch (error: any) {
    console.error('Google Sign-In Error:', error);
    set({
      error: 'Erreur lors de la connexion avec Google.',
      isLoading: false,
    });
  }
},
// In src/store/useAuthStore.ts, add to the AuthStore interface:

switchOrganization: async (organizationId: string) => {
  const { currentUser } = get();
  if (!currentUser) {
    throw new Error('No authenticated user');
  }

  set({ isLoading: true, error: null });

  try {
    // Verify user has access to this organization
    if (!currentUser.organizations[organizationId]) {
      throw new Error('User does not have access to this organization');
    }

    // Get the organization document
    const orgDoc = await getDoc(doc(db, 'organizations', organizationId));
    if (!orgDoc.exists()) {
      throw new Error('Organization not found');
    }

    const orgData = orgDoc.data() as Organization;
    
    // Verify user is still a member
    const member = orgData.members.find(m => m.userId === currentUser.id);
    if (!member) {
      throw new Error('User is not a member of this organization');
    }

    // Update user's default organization in Firestore
    await updateDoc(doc(db, 'users', currentUser.id), {
      defaultOrganizationId: organizationId,
      updatedAt: serverTimestamp()
    });

    // Update local state
    set({
      currentUser: {
        ...currentUser,
        defaultOrganizationId: organizationId
      },
      currentOrganization: {
        ...orgData,
        id: orgDoc.id
      },
      isLoading: false,
      error: null
    });
  } catch (error) {
    const errorMessage = error instanceof Error ? error.message : 'Failed to switch organization';
    set({ 
      error: errorMessage,
      isLoading: false 
    });
    throw error;
  }
},

// Add helper method to get user's role in current organization
getUserRole: () => {
  const { currentUser, currentOrganization } = get();
  if (!currentUser || !currentOrganization) return null;

  const member = currentOrganization.members.find(
    m => m.userId === currentUser.id
  );
  return member?.role || null;
},

fetchOrganization: async (orgId: string) => {
  try {
    const orgDoc = await getDoc(doc(db, 'organizations', orgId));
    if (!orgDoc.exists()) {
      throw new Error('Organization not found');
    }
    return { id: orgDoc.id, ...orgDoc.data() } as Organization;
  } catch (error) {
    console.error('Error fetching organization:', error);
    throw error;
  }
},
// In useAuthStore.ts, update the addUserToOrganization function:

addUserToOrganization: async (email: string, role: UserRole) => {
  const { currentUser, currentOrganization } = get();
  if (!currentUser || !currentOrganization) {
    throw new Error('User not authenticated or no active organization');
  }

  set({ isLoading: true, error: null });

  try {
    // Check if user exists
    const usersQuery = query(
      collection(db, 'users'),
      where('email', '==', email.toLowerCase())
    );
    const userSnapshot = await getDocs(usersQuery);

    if (userSnapshot.empty) {
      set({ 
        error: "Cet utilisateur n'existe pas. Il doit d'abord créer un compte.",
        isLoading: false 
      });
      return;
    }

    const invitedUser = {
      id: userSnapshot.docs[0].id,
      ...userSnapshot.docs[0].data()
    } as User;

    // Create invitation
    const inviteId = crypto.randomUUID();
    const timestamp = new Date().toISOString();
    const expiryDate = new Date();
    expiryDate.setDate(expiryDate.getDate() + 7);

    const invitation = {
      id: inviteId,
      organizationId: currentOrganization.id,
      userId: invitedUser.id,
      email: email.toLowerCase(),
      role,
      status: 'pending',
      invitedBy: currentUser.id,
      createdAt: timestamp,
      expiresAt: expiryDate.toISOString()
    };

    // Add invitation to Firestore
    await setDoc(doc(db, 'organizationInvites', inviteId), invitation);

    // Send invitation email using the dedicated function
    const inviteLink = `${window.location.origin}/organization/invite/${inviteId}`;
    try {
      await sendOrganizationInvite(
        email,
        `${currentUser.firstName} ${currentUser.lastName}`.trim(),
        currentOrganization.name,
        inviteLink
      );
    } catch (emailError) {
      console.error('Error sending invitation email:', emailError);
      set({ error: "L'invitation a été créée mais l'email n'a pas pu être envoyé." });
    }

    // Add member to organization
    const member: OrganizationMember = {
      userId: invitedUser.id,
      email: email.toLowerCase(),
      name: `${invitedUser.firstName} ${invitedUser.lastName}`.trim(),
      role,
      invitationAccepted: false,
      joinedAt: timestamp
    };

    const updatedMembers = [...currentOrganization.members, member];

    await updateDoc(doc(db, 'organizations', currentOrganization.id), {
      members: updatedMembers,
      updatedAt: serverTimestamp()
    });

    // Update local state
    set(state => ({
      currentOrganization: state.currentOrganization ? {
        ...state.currentOrganization,
        members: updatedMembers
      } : null,
      isLoading: false,
      error: null
    }));

  } catch (error) {
    console.error('Error adding user:', error);
    set({ 
      error: "Une erreur s'est produite lors de l'ajout de l'utilisateur. Veuillez réessayer.",
      isLoading: false 
    });
    throw error;
  }
},

  logout: async () => {
    set({ isLoading: true });
    await signOut(auth);
    set({
      currentUser: null,
      currentOrganization: null,
      isAuthenticated: false,
      error: null,
      isLoading: false,
    });
  },
}));
