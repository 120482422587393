import React from 'react';
import { useStore } from '../store/useStore';
import { useCookbookStore } from '../store/useCookbookStore';
import { useAuthStore } from '../store/useAuthStore';
import { Recipe } from '../types';
import { RecipeCard } from './recipe/RecipeCard';
import { AlertCircle, ChefHat } from 'lucide-react';

export function RecipeList() {
  const recipes = useStore((state) => state.recipes) || [];
  const publicRecipes = useStore((state) => state.publicRecipes) || [];
  const publicRecipeIds = useCookbookStore((state) => state.publicRecipeIds);
  const searchTerm = useCookbookStore((state) => state.searchTerm);
  const error = useStore((state) => state.error);
  const removeRecipe = useStore((state) => state.removeRecipe);
  const addToMealPlan = useStore((state) => state.addToMealPlan);
  const currentUser = useAuthStore((state) => state.currentUser);
  const organization = useAuthStore((state) => state.organization);

  // Get all recipes from the cookbook (both private and saved public ones)
  // Ensure unique recipes by using a Map with composite keys
  const cookbookRecipes = React.useMemo(() => {
    const recipeMap = new Map<string, Recipe>();
    
    // Add private recipes
    recipes.forEach(recipe => {
      recipeMap.set(`private-${recipe.id}`, recipe);
    });
    
    // Add saved public recipes
    publicRecipes
      .filter(recipe => publicRecipeIds.includes(recipe.id))
      .forEach(recipe => {
        recipeMap.set(`public-${recipe.id}`, recipe);
      });
    
    return Array.from(recipeMap.values());
  }, [recipes, publicRecipes, publicRecipeIds]);

  // Filter recipes based on search term
  const filteredRecipes = searchTerm
    ? cookbookRecipes.filter(recipe =>
        recipe.name.toLowerCase().includes(searchTerm.toLowerCase())
      )
    : cookbookRecipes;

  const handleAddToMealPlan = async (recipe: Recipe) => {
    if (!currentUser) return;
    
    try {
      await addToMealPlan({
        recipeId: recipe.id,
        date: new Date().toISOString().split('T')[0],
        mealType: 'dinner',
        userId: currentUser.id,
        organizationId: currentUser.defaultOrganizationId
      });
    } catch (error) {
      console.error('Error adding to meal plan:', error);
    }
  };

  const canEditRecipe = (recipe: Recipe) => {
    if (!currentUser || !organization) return false;
    return (
      currentUser.role === 'owner' ||
      currentUser.role === 'contributor' ||
      recipe.createdBy.id === currentUser.id
    );
  };

  if (!filteredRecipes.length) {
    return (
      <div className="min-h-[40vh] flex flex-col items-center justify-center bg-white rounded-xl shadow-sm p-8">
        <ChefHat className="h-16 w-16 text-menioo-green/20 mb-4" />
        <p className="text-menioo-navy font-medium">Your cookbook is empty.</p>
        <p className="text-gray-500 text-sm mt-2">
          Add recipes from the discovery page or create your own!
        </p>
      </div>
    );
  }

  return (
    <div>
      {error && (
        <div className="mb-4 bg-red-50 border border-red-200 text-red-700 px-4 py-3 rounded relative flex items-center">
          <AlertCircle className="h-5 w-5 mr-2" />
          <span>{error}</span>
        </div>
      )}
      
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4">
        {filteredRecipes.map((recipe) => (
          <RecipeCard
            key={`${recipe.isPublic ? 'public' : 'private'}-${recipe.id}`}
            recipe={recipe}
            canEdit={canEditRecipe(recipe)}
            onDelete={removeRecipe}
            onAddToMealPlan={handleAddToMealPlan}
          />
        ))}
      </div>
    </div>
  );
}