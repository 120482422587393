import React, { useEffect, useState } from 'react';
import { collection, query, where, orderBy, limit, onSnapshot } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import { useCollection } from 'react-firebase-hooks/firestore';
import { Recipe } from '../../types';
import { RecipeCard } from '../recipe/RecipeCard';

const RECIPES_LIMIT = 20; // Reduced from 30 to improve performance

interface DiscoveryViewsProps {
  activeView: 'latest' | 'showcase' | 'popular';
}

export function DiscoveryViews({ activeView }: DiscoveryViewsProps) {
  const [recipes, setRecipes] = useState<Recipe[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    setLoading(true);
    setError(null);

    // Create query based on active view
    const selectedQuery = {
      latest: query(
        collection(db, 'recipes'),
        where('isPublic', '==', true),
        orderBy('createdAt', 'desc'),
        limit(RECIPES_LIMIT)
      ),
      showcase: query(
        collection(db, 'recipes'),
        where('isPublic', '==', true),
        where('showcase', '==', true),
        orderBy('createdAt', 'desc'),
        limit(RECIPES_LIMIT)
      ),
      popular: query(
        collection(db, 'recipes'),
        where('isPublic', '==', true),
        orderBy('bookmarkCount', 'desc'),
        limit(RECIPES_LIMIT)
      ),
    }[activeView];

    // Set up subscription with proper cleanup
    const unsubscribe = onSnapshot(
      selectedQuery,
      (snapshot) => {
        const recipeData = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        } as Recipe));
        setRecipes(recipeData);
        setLoading(false);
      },
      (err) => {
        console.error('Error fetching recipes:', err);
        setError(err as Error);
        setLoading(false);
      }
    );

    // Cleanup subscription on unmount or when activeView changes
    return () => {
      unsubscribe();
      setRecipes([]); // Clear recipes to prevent memory leaks
    };
  }, [activeView]);

  if (loading) {
    return (
      <div className="text-center py-8">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-menioo-green mx-auto"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center py-8 text-red-600">
        Une erreur est survenue lors du chargement des recettes.
      </div>
    );
  }

  return (
    <div className="space-y-8 px-4 sm:px-6 lg:px-8">
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4">
        {recipes.map((recipe) => (
          <RecipeCard
            key={recipe.id}
            recipe={recipe}
            canEdit={false}
            isDiscoveryPage={true}
            onDelete={async () => {}}
          />
        ))}
      </div>

      {!loading && !error && recipes.length === 0 && (
        <div className="text-center py-8 text-gray-500">
          Aucune recette disponible pour le moment.
        </div>
      )}
    </div>
  );
}
