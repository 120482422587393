// src/components/planning/DraggableRecipe.tsx

import React from 'react';
import { Clock, Users, Plus } from 'lucide-react';
import { Recipe } from '../../types';
import { Draggable } from '@hello-pangea/dnd';
import { useMealPlanManagerStore } from '../../store/useMealPlanManagerStore';
import { useAuthStore } from '../../store/useAuthStore';
import { addRecipeToMealPlanAndGrocery } from '../../utils/mealPlanUtils';

interface DraggableRecipeProps {
  recipe: Recipe;
  index: number;
  onQuickAdd: (recipe: Recipe) => void;
  mealPlanId?: string;
}

export function DraggableRecipe({
  recipe,
  index,
  onQuickAdd,
  mealPlanId
}: DraggableRecipeProps) {
  const draggableId = mealPlanId ? `${recipe.id}-${mealPlanId}` : recipe.id;
  const currentUser = useAuthStore((state) => state.currentUser);

  const handleQuickAdd = async (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    if (!currentUser?.defaultOrganizationId) return;

    try {
      await addRecipeToMealPlanAndGrocery(
        recipe,
        '', // Empty date since we don't need it
        '', // Empty meal type since we don't need it
        currentUser.id,
        currentUser.defaultOrganizationId,
        //recipe.servings
      );
    } catch (error) {
      console.error('Error adding recipe:', error);
    }
  };

  return (
    <Draggable draggableId={draggableId} index={index}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className="relative group rounded-lg overflow-hidden shadow hover:shadow-lg transition-all duration-200 bg-white"
          style={{
            ...provided.draggableProps.style,
            opacity: snapshot.isDragging ? 0.5 : 1,
          }}
        >
          <div className="block relative aspect-[4/3] w-full">
            {recipe.image ? (
              <img
                src={recipe.image}
                alt={recipe.name}
                className="w-full h-full object-cover rounded-t-lg"
              />
            ) : (
              <div className="w-full h-full bg-gray-200 flex items-center justify-center">
                <span className="text-gray-500">No image</span>
              </div>
            )}

            <div className="absolute inset-0 bg-gradient-to-t from-black/70 to-transparent pointer-events-none" />

            <div className="absolute bottom-0 p-4 text-white">
              <h3 className="text-sm md:text-lg font-semibold mb-2 line-clamp-2">
                {recipe.name}
              </h3>
              <div className="flex items-center space-x-2 text-xs md:text-sm">
                <div className="flex items-center">
                  <Clock className="h-4 w-4 mr-1" />
                  <span>{recipe.prepTime + recipe.cookTime} mins</span>
                </div>
                <div className="flex items-center">
                  <Users className="h-4 w-4 mr-1" />
                  <span>{recipe.servings}</span>
                </div>
              </div>
            </div>

            <button
              onClick={handleQuickAdd}
              className="absolute top-2 right-2 bg-white p-2 rounded-full shadow-lg hover:bg-green-500 hover:text-white transition"
            >
              <Plus className="h-5 w-5" />
            </button>
          </div>
        </div>
      )}
    </Draggable>
  );
}
