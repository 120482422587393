import React from 'react';
import { MainNavigation } from './navigation/MainNavigation';

export function LandingPage() {
  return (
    <div className="min-h-screen bg-menioo-cream">
      {/* Main Navigation */}
      <MainNavigation />

      {/* Main Content */}
      <main className="max-w-7xl mx-auto px-2 sm:px-4 lg:px-6 py-4">
        <section className="text-center py-8">
          <h1 className="text-4xl font-bold text-menioo-navy">
            Simplifiez vos repas avec <span className="text-menioo-orange">Menioo</span>
          </h1>
          <p className="mt-4 text-lg text-gray-700">
            Découvrez des recettes rapides et simples, planifiez vos repas et gérez vos listes de courses 
            en quelques clics. Menioo vous aide à gagner du temps et à simplifier votre quotidien.
          </p>
          <div className="mt-6">
            <button
              className="bg-menioo-orange text-white px-6 py-3 rounded-full font-semibold text-lg hover:bg-orange-700 transition"
              onClick={() => (window.location.href = '/recipes')}
            >
              Explorer les recettes
            </button>
            <button
              className="ml-4 bg-white border border-menioo-orange text-menioo-orange px-6 py-3 rounded-full font-semibold text-lg hover:bg-orange-100 transition"
              onClick={() => (window.location.href = '/signup')}
            >
              Inscrivez-vous gratuitement
            </button>
          </div>
        </section>

        {/* Features Section */}
        <section className="mt-12 grid grid-cols-1 md:grid-cols-3 gap-6">
          <div className="p-6 bg-white rounded-lg shadow-md">
            <h2 className="text-xl font-bold text-menioo-navy">Recettes pour tous les jours</h2>
            <p className="mt-2 text-gray-600">
              Trouvez des recettes rapides et faciles adaptées à vos besoins quotidiens. 
              Ajoutez-les directement à vos listes de courses.
            </p>
          </div>
          <div className="p-6 bg-white rounded-lg shadow-md">
            <h2 className="text-xl font-bold text-menioo-navy">Planification simplifiée</h2>
            <p className="mt-2 text-gray-600">
              Planifiez vos repas de la semaine grâce à une interface intuitive et flexible. 
              Organisez vos recettes en fonction de votre emploi du temps.
            </p>
          </div>
          <div className="p-6 bg-white rounded-lg shadow-md">
            <h2 className="text-xl font-bold text-menioo-navy">Liste de courses intelligente</h2>
            <p className="mt-2 text-gray-600">
              Générez automatiquement des listes de courses à partir de vos repas planifiés. 
              Ajoutez des articles personnalisés en quelques secondes.
            </p>
          </div>
        </section>

        {/* Testimonials Section */}
        <section className="mt-16 text-center">
          <h2 className="text-3xl font-bold text-menioo-navy">Ils adorent Menioo</h2>
          <p className="mt-4 text-lg text-gray-700">
            Des centaines d'utilisateurs utilisent Menioo pour simplifier leur vie quotidienne. 
            Rejoignez-les dès aujourd'hui !
          </p>
        </section>
      </main>

      {/* Footer */}
      <footer className="bg-menioo-navy text-white py-6 mt-8">
        <div className="max-w-7xl mx-auto px-2 sm:px-4 lg:px-6">
          <div className="text-sm">
            © {new Date().getFullYear()} Menioo. Tous droits réservés.
          </div>
        </div>
      </footer>
    </div>
  );
}