import React from 'react';
import { Bot, Hand } from 'lucide-react';

interface AutomationToggleProps {
  isEnabled: boolean;
  onToggle: () => void;
}

export function AutomationToggle({ isEnabled, onToggle }: AutomationToggleProps) {
  return (
    <button
      onClick={onToggle}
      className={`flex items-center space-x-2 px-4 py-2 rounded-full transition-all duration-300 ${
        isEnabled 
          ? 'bg-menioo-orange text-white shadow-lg' 
          : 'bg-gray-100 text-gray-600'
      }`}
      title={isEnabled ? 'Mode automatique activé' : 'Mode manuel activé'}
    >
      <div className="relative w-14 h-7 rounded-full bg-white/20 shadow-inner">
        <div
          className={`absolute top-1 h-5 w-5 rounded-full bg-white shadow-md transform transition-transform duration-300 ${
            isEnabled ? 'translate-x-7' : 'translate-x-1'
          }`}
        />
      </div>
      <div className="flex items-center space-x-2">
        {isEnabled ? (
          <>
            <Bot className="h-5 w-5" />
            <span className="font-medium">Auto</span>
          </>
        ) : (
          <>
            <Hand className="h-5 w-5" />
            <span className="font-medium">Manuel</span>
          </>
        )}
      </div>
    </button>
  );
}