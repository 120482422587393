// src/components/organization/AcceptInvite.tsx
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { ChefHat, Loader, Check, AlertCircle } from 'lucide-react';
import { doc, getDoc, updateDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import { useAuthStore } from '../../store/useAuthStore';

export function AcceptInvite() {
  const { inviteId } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const currentUser = useAuthStore((state) => state.currentUser);
  const switchOrganization = useAuthStore((state) => state.switchOrganization);

useEffect(() => {
  const acceptInvite = async () => {
    console.log('Starting invite acceptance with ID:', inviteId);
    console.log('Current user:', currentUser);

    if (!inviteId || !currentUser) {
      console.log('Missing inviteId or currentUser');
      setError("Information d'invitation invalide ou utilisateur non connecté");
      setIsLoading(false);
      return;
    }

   try {
  // Get invite document with proper type checking
  const inviteRef = doc(db, 'organizationInvites', inviteId);
  const inviteDoc = await getDoc(inviteRef);
  
  if (!inviteDoc.exists()) {
    console.error('Invite not found:', inviteId);
    setError("Cette invitation n'existe pas");
    return;
  }

  const invite = inviteDoc.data();
  console.log('Processing invite:', {
    inviteId,
    invite,
    currentUser: {
      id: currentUser.id,
      email: currentUser.email
    }
  });

  // Validate invite data
  if (!invite.organizationId || !invite.email || !invite.role) {
    console.error('Invalid invite data:', invite);
    setError("Données d'invitation invalides");
    return;
  }

  // Check status
  if (invite.status !== 'pending') {
    console.error('Invalid invite status:', invite.status);
    setError("Cette invitation n'est plus valide");
    return;
  }

  // Check expiration
  const now = new Date();
  const expiryDate = new Date(invite.expiresAt);
  if (now > expiryDate) {
    console.error('Invite expired:', {
      now,
      expiryDate,
      inviteId
    });
    setError("Cette invitation a expiré");
    return;
  }

  // Verify email match
  if (invite.email.toLowerCase() !== currentUser.email.toLowerCase()) {
    console.error('Email mismatch:', {
      inviteEmail: invite.email,
      userEmail: currentUser.email
    });
    setError("Cette invitation ne correspond pas à votre compte");
    return;
  }

  // Get organization document
  const orgRef = doc(db, 'organizations', invite.organizationId);
  const orgDoc = await getDoc(orgRef);
  
  if (!orgDoc.exists()) {
    console.error('Organization not found:', invite.organizationId);
    setError("L'organisation n'existe plus");
    return;
  }

  const orgData = orgDoc.data();
  console.log('Organization data:', {
    id: orgDoc.id,
    members: orgData.members?.length
  });

  // Update user's organizations map
  await updateDoc(doc(db, 'users', currentUser.id), {
    [`organizations.${invite.organizationId}`]: {
      organizationId: invite.organizationId,
      role: invite.role,
      joinedAt: new Date().toISOString()
    },
    updatedAt: serverTimestamp()
  });

  // Update organization members
  const existingMember = orgData.members?.find(
    (m: any) => m.email.toLowerCase() === currentUser.email.toLowerCase()
  );

  const updatedMembers = existingMember 
    ? orgData.members.map((member: any) =>
        member.email.toLowerCase() === currentUser.email.toLowerCase()
          ? {
              ...member,
              userId: currentUser.id,
              invitationAccepted: true,
              role: invite.role
            }
          : member
      )
    : [
        ...(orgData.members || []),
        {
          userId: currentUser.id,
          email: currentUser.email,
          name: `${currentUser.firstName} ${currentUser.lastName}`.trim(),
          role: invite.role,
          invitationAccepted: true,
          joinedAt: new Date().toISOString()
        }
      ];

  await updateDoc(orgRef, {
    members: updatedMembers,
    updatedAt: serverTimestamp()
  });

  // Update invite status
  await updateDoc(inviteRef, {
    status: 'accepted',
    acceptedAt: new Date().toISOString(),
    updatedAt: serverTimestamp()
  });

  // Switch to the new organization
  await switchOrganization(invite.organizationId);

  // Redirect after success
  setTimeout(() => {
    navigate('/cookbook');
  }, 2000);

} catch (error) {
  console.error('Error accepting invite:', error);
  setError("Une erreur s'est produite lors de l'acceptation de l'invitation");
  throw error; // Re-throw to see full error in console
}

    };

    acceptInvite();
  }, [inviteId, currentUser, navigate, switchOrganization]);

  if (isLoading) {
    return (
      <div className="min-h-screen bg-menioo-cream flex items-center justify-center">
        <div className="text-center">
          <Loader className="h-12 w-12 animate-spin text-menioo-green mx-auto" />
          <h2 className="mt-4 text-xl font-medium text-menioo-navy">
            Traitement de l'invitation...
          </h2>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen bg-menioo-cream flex items-center justify-center">
        <div className="text-center">
          <AlertCircle className="h-12 w-12 text-red-500 mx-auto" />
          <h2 className="mt-4 text-xl font-medium text-menioo-navy">
            {error}
          </h2>
          <Link
            to="/cookbook"
            className="mt-4 inline-block text-menioo-green hover:text-menioo-green-dark"
          >
            Retour à l'accueil
          </Link>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-menioo-cream flex items-center justify-center">
      <div className="text-center">
        <div className="rounded-full bg-menioo-green/10 p-4 mx-auto w-fit">
          <Check className="h-12 w-12 text-menioo-green" />
        </div>
        <h2 className="mt-4 text-xl font-medium text-menioo-navy">
          Invitation acceptée avec succès !
        </h2>
        <p className="mt-2 text-gray-600">
          Vous allez être redirigé...
        </p>
      </div>
    </div>
  );
}
