import React, { useState } from 'react';
import { ChefHat, Printer, Share2, Check, Copy, ArrowLeft } from 'lucide-react';
import { useGroceryListStore } from '../../store/useGroceryListStore';
import { Link } from 'react-router-dom';

export function PrintHeader() {
  const [shareUrl, setShareUrl] = useState<string | null>(null);
  const [copied, setCopied] = useState(false);
  const { currentList, generateShareToken } = useGroceryListStore();

  const handlePrint = () => {
    window.print();
  };

  const handleShare = async () => {
    if (!currentList) return;
    
    try {
      const token = await generateShareToken(currentList.id);
      const url = `${window.location.origin}/printable-grocery-list/${token}`;
      setShareUrl(url);
    } catch (error) {
      console.error('Error sharing list:', error);
    }
  };

  const handleCopy = async () => {
    if (!shareUrl) return;
    
    try {
      await navigator.clipboard.writeText(shareUrl);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (error) {
      console.error('Error copying to clipboard:', error);
    }
  };

  return (
    <div className="bg-white shadow-sm print:shadow-none">
      <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 py-4">
        <div className="flex items-center justify-between">
          {/* Logo and Title */}
          <div className="flex items-center space-x-3">
            <Link to="/grocery-list" className="flex items-center space-x-2">
              <ArrowLeft className="h-5 w-5 text-menioo-navy sm:hidden" />
              <ChefHat className="h-8 w-8 text-menioo-green" />
              <div className="hidden sm:block">
                <h1 className="text-xl font-display font-bold text-menioo-navy">
                  Menioo
                </h1>
                <p className="text-xs text-gray-500">Liste de courses</p>
              </div>
            </Link>
          </div>
          
          {/* Action Buttons */}
          <div className="flex items-center space-x-2 sm:space-x-4 print:hidden">
            {shareUrl ? (
              <div className="flex items-center">
                <input
                  type="text"
                  value={shareUrl}
                  readOnly
                  className="hidden sm:block text-sm border border-gray-300 rounded-md px-3 py-2 w-64"
                />
                <button
                  onClick={handleCopy}
                  className="p-2 text-gray-500 hover:text-gray-700 rounded-md hover:bg-gray-100"
                  title={copied ? "Copié !" : "Copier"}
                >
                  {copied ? (
                    <Check className="h-5 w-5 text-menioo-green" />
                  ) : (
                    <Copy className="h-5 w-5" />
                  )}
                </button>
              </div>
            ) : (
              <button
                onClick={handleShare}
                className="p-2 text-gray-500 hover:text-gray-700 rounded-md hover:bg-gray-100"
                title="Partager"
              >
                <Share2 className="h-5 w-5" />
              </button>
            )}
            
            <button 
              onClick={handlePrint}
              className="p-2 text-gray-500 hover:text-gray-700 rounded-md hover:bg-gray-100"
              title="Imprimer"
            >
              <Printer className="h-5 w-5" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
