import React, { useState } from 'react';
import { X, AlertCircle } from 'lucide-react';
import { format, startOfWeek } from 'date-fns';
import { fr } from 'date-fns/locale';
import { useMealPlanManagerStore } from '../../store/useMealPlanManagerStore';
import { useAuthStore } from '../../store/useAuthStore';
import { collection, doc, query, where, getDocs, updateDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../../lib/firebase';

interface NewMealPlanDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

export function NewMealPlanDialog({ isOpen, onClose }: NewMealPlanDialogProps) {
  const defaultName = `Menu du ${format(new Date(), 'd MMMM', { locale: fr })}`;
  const [name, setName] = useState(defaultName);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const currentUser = useAuthStore((state) => state.currentUser);
  const { currentMealPlan, createNewMealPlan, fetchMealPlans } = useMealPlanManagerStore();

  if (!isOpen) return null;

  const handleArchiveCurrentPlan = async () => {
    if (!currentMealPlan) return;

    try {
      const currentUser = useAuthStore.getState().currentUser;

      // Archive current meal plan
      await updateDoc(doc(db, 'newMealPlans', currentMealPlan.id), {
        status: 'archived',
        updatedAt: serverTimestamp(),
        updatedBy: currentUser?.id,
      });

      // Archive associated grocery list
      const groceryListQuery = query(
        collection(db, 'groceryLists'),
        where('organizationId', '==', currentUser?.defaultOrganizationId),
        where('mealPlanId', '==', currentMealPlan.id),
        where('status', '==', 'active')
      );

      const groceryListSnapshot = await getDocs(groceryListQuery);
      if (!groceryListSnapshot.empty) {
        const groceryListId = groceryListSnapshot.docs[0].id;
        await updateDoc(doc(db, 'groceryLists', groceryListId), {
          status: 'archived',
          updatedAt: serverTimestamp(),
        });
      }
    } catch (error) {
      console.error('Error archiving current meal plan or grocery list:', error);
      throw error;
    }
  };

  const handleConfirm = async () => {
    if (!currentUser?.defaultOrganizationId) return;

    setIsSubmitting(true);
    setError(null);

    try {
      // Archive the current meal plan and associated grocery list
      await handleArchiveCurrentPlan();

      // Create the new meal plan
      await createNewMealPlan(name);

      // Refresh the meal plans
      await fetchMealPlans();

      setShowConfirmation(false);
      setName(defaultName);
      onClose();
    } catch (error) {
      console.error('Error creating new meal plan:', error);
      setError('Failed to create meal plan. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  if (showConfirmation) {
    return (
      <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
        <div className="bg-white rounded-xl w-full max-w-md p-6">
          <div className="flex items-center space-x-2 text-amber-600 mb-4">
            <AlertCircle className="h-5 w-5" />
            <h3 className="text-lg font-medium">Confirmation</h3>
          </div>

          <p className="text-gray-600 mb-6">
            Le menu courant sera archivé ainsi que la liste de course, il ne sera plus possible de les modifier.
          </p>

          {error && (
            <div className="mb-4 text-sm text-red-600">
              {error}
            </div>
          )}

          <div className="flex justify-end space-x-3">
            <button
              onClick={() => setShowConfirmation(false)}
              disabled={isSubmitting}
              className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 disabled:opacity-50"
            >
              Annuler
            </button>
            <button
              onClick={handleConfirm}
              disabled={isSubmitting}
              className="px-4 py-2 text-sm font-medium text-white bg-menioo-green rounded-md hover:bg-menioo-green-dark disabled:opacity-50"
            >
              {isSubmitting ? 'Création...' : 'Confirmer'}
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-xl w-full max-w-md">
        <div className="flex items-center justify-between p-4 border-b">
          <h3 className="text-lg font-medium text-menioo-navy">Nouveau Menu</h3>
          <button onClick={onClose} className="text-gray-400 hover:text-gray-600">
            <X className="h-5 w-5" />
          </button>
        </div>

        <form onSubmit={(e) => { e.preventDefault(); setShowConfirmation(true); }} className="p-4">
          <div className="mb-4">
            <label htmlFor="name" className="block text-sm font-medium text-gray-700">
              Nom du menu
            </label>
            <input
              type="text"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-menioo-green focus:ring-menioo-green sm:text-sm"
              required
            />
          </div>

          <div className="flex justify-end space-x-3">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
            >
              Annuler
            </button>
            <button
              type="submit"
              className="px-4 py-2 text-sm font-medium text-white bg-menioo-green rounded-md hover:bg-menioo-green-dark"
            >
              Créer
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
