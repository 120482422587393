import { create } from 'zustand';
import { collection, query, getDocs, onSnapshot } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { StandardIngredient } from '../types/ingredients';

interface IngredientSearchStore {
  ingredients: StandardIngredient[];
  searchQuery: string;
  selectedIndex: number;
  isLoading: boolean;
  error: string | null;
  setSearchQuery: (query: string) => void;
  setSelectedIndex: (index: number) => void;
  getFilteredIngredients: () => StandardIngredient[];
  searchIngredients: (query: string) => StandardIngredient[];
  initializeIngredients: () => () => void;
  reset: () => void;
}

function normalizeString(str: string) {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();
}

export const useIngredientSearchStore = create<IngredientSearchStore>((set, get) => ({
  ingredients: [],
  searchQuery: '',
  selectedIndex: -1,
  isLoading: false,
  error: null,

  setSearchQuery: (query: string) => {
    console.log('Setting search query:', query);
    set({ searchQuery: query });
  },

  setSelectedIndex: (index: number) => {
    set({ selectedIndex: index });
  },

  getFilteredIngredients: () => {
    const { searchQuery, ingredients } = get();
    const browserLanguage = navigator.language.split('-')[0].toLowerCase();
  
    const languageFilteredIngredients = ingredients.filter(
      ing => ing.language?.toLowerCase() === browserLanguage
    );
  
    if (!searchQuery.trim()) {
      return languageFilteredIngredients.slice(0, 10);
    }
  
    const normalizedQuery = normalizeString(searchQuery);
  
    const results = languageFilteredIngredients
      .filter(ingredient => {
        if (!ingredient?.name) return false;
        
        const normalizedName = normalizeString(ingredient.name);
        const nameMatch = normalizedName.includes(normalizedQuery);
        
        const aliasMatch = ingredient.aliases?.some(alias => 
          normalizeString(alias).includes(normalizedQuery)
        );
  
        return nameMatch || aliasMatch;
      })
      .sort((a, b) => {
        // Prioritize exact matches
        const aExact = a.name.toLowerCase() === normalizedQuery;
        const bExact = b.name.toLowerCase() === normalizedQuery;
        if (aExact && !bExact) return -1;
        if (!aExact && bExact) return 1;

        // Then prioritize starts with
        const aStartsWith = a.name.toLowerCase().startsWith(normalizedQuery);
        const bStartsWith = b.name.toLowerCase().startsWith(normalizedQuery);
        if (aStartsWith && !bStartsWith) return -1;
        if (!aStartsWith && bStartsWith) return 1;

        // Finally sort alphabetically
        return a.name.localeCompare(b.name);
      })
      .slice(0, 10);

    console.log('Final filtered results:', results);
    return results;
  },

  searchIngredients: (query: string) => {
    console.log('Searching ingredients with query:', query);
    set({ searchQuery: query, selectedIndex: -1 });
    return get().getFilteredIngredients();
  },

  initializeIngredients: () => {
    set({ isLoading: true });
    console.log('Initializing ingredients subscription');

    // First, get all ingredients immediately
    getDocs(collection(db, 'standardIngredients'))
      .then((snapshot) => {
        const ingredients: StandardIngredient[] = [];
        snapshot.forEach((doc) => {
          ingredients.push({ id: doc.id, ...doc.data() } as StandardIngredient);
        });
        console.log('Initial ingredients loaded:', ingredients);
        set({ ingredients, isLoading: false });
      })
      .catch((error) => {
        console.error('Error loading ingredients:', error);
        set({ error: error.message, isLoading: false });
      });

    // Then set up real-time updates
    const unsubscribe = onSnapshot(
      collection(db, 'standardIngredients'),
      (snapshot) => {
        const ingredients: StandardIngredient[] = [];
        snapshot.forEach((doc) => {
          ingredients.push({ id: doc.id, ...doc.data() } as StandardIngredient);
        });
        console.log('Ingredients updated:', ingredients);
        set({ ingredients, error: null });
      },
      (error) => {
        console.error('Error in ingredients subscription:', error);
        set({ error: error.message });
      }
    );

    return unsubscribe;
  },

  reset: () => {
    console.log('Resetting search state');
    set({ searchQuery: '', selectedIndex: -1 });
  }
}));