import React from 'react';
import { Recipe } from '../../types';
import { DraggableRecipe } from './DraggableRecipe';
import { Droppable } from '@hello-pangea/dnd';

interface RecipeGridProps {
  recipes: Recipe[];
  droppableId?: string;
  className?: string;
}

export function RecipeGrid({ 
  recipes, 
  droppableId = 'recipe-grid',
  className = 'grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4'
}: RecipeGridProps) {
  return (
    <Droppable droppableId={droppableId} direction="horizontal">
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.droppableProps}
          className={`${className} ${
            snapshot.isDraggingOver ? 'bg-menioo-green/5' : ''
          }`}
        >
          {recipes.map((recipe, index) => (
            <DraggableRecipe key={recipe.id} recipe={recipe} index={index} />
          ))}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
}
