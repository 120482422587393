import React, { useState } from 'react';
import { format, addDays, startOfWeek } from 'date-fns';
import { fr } from 'date-fns/locale';
import { ChevronLeft, ChevronRight, ShoppingBasket } from 'lucide-react';
import { DragDropContext, DropResult } from '@hello-pangea/dnd';
import { useStore } from '../../store/useStore';
import { useMealPlanManagerStore } from '../../store/useMealPlanManagerStore';
import { useAuthStore } from '../../store/useAuthStore';
import { Recipe } from '../../types';
import { RecipeGrid } from './RecipeGrid';
import { MealPlanSectionList } from './MealPlanSectionList';
import { MealSectionCalendar } from './MealSectionCalendar';
import { addRecipeToMealPlanAndGrocery, addRecipeIngredientsManually } from '../../utils/mealPlanUtils';

interface CalendarViewProps {
  recipes: Recipe[];
  onQuickAdd: (recipe: Recipe) => void;
  isAutomationEnabled: boolean;
  onOpenGroceryList: () => void;
}

export function CalendarView({ recipes, onQuickAdd, isAutomationEnabled, onOpenGroceryList }: CalendarViewProps) {
  const [startDate, setStartDate] = useState(() => startOfWeek(new Date(), { locale: fr }));
  const { currentMealPlan, updateMealPlan } = useMealPlanManagerStore();
  const currentUser = useAuthStore((state) => state.currentUser);

  const weekDays = Array.from({ length: 7 }, (_, i) => addDays(startDate, i));

  const handlePreviousWeek = () => {
    setStartDate((date) => addDays(date, -7));
  };

  const handleNextWeek = () => {
    setStartDate((date) => addDays(date, 7));
  };

  const handleDragEnd = async (result: DropResult) => {
    const { source, destination, draggableId } = result;

    if (!destination || !currentUser?.defaultOrganizationId || !currentMealPlan) return;

    // Prevent dropping into recipe-grid
    if (destination.droppableId === 'recipe-grid') return;

    try {
      if (source.droppableId === 'recipe-grid') {
        const recipe = recipes.find((r) => r.id === draggableId);
        if (!recipe) return;

        let destDate = null;
        let destMealType = null;

        if (destination.droppableId !== 'unplanned-recipes') {
          const [date, mealType] = destination.droppableId.split('_');
          destDate = date;
          destMealType = mealType;
        }

        await addRecipeToMealPlanAndGrocery(
          recipe,
          destDate || '',
          destMealType || '',
          currentUser.id,
          currentUser.defaultOrganizationId
        );
      } else {
        const entryId = draggableId;
        const entry = currentMealPlan.mealEntries.find((e) => e.id === entryId);
        if (!entry) return;

        let newDate = '';
        let newMealType = '';

        if (destination.droppableId !== 'unplanned-recipes') {
          [newDate, newMealType] = destination.droppableId.split('_');
        }

        const updatedEntries = currentMealPlan.mealEntries.map((e) =>
          e.id === entryId ? { ...e, date: newDate, mealType: newMealType } : e
        );

        await updateMealPlan(currentMealPlan.id, {
          mealEntries: updatedEntries,
          updatedAt: new Date()
        });
      }
    } catch (error) {
      console.error('Error handling drag and drop:', error);
    }
  };

  const handleAddWeekToGroceryList = async () => {
    if (!currentUser?.defaultOrganizationId || !currentMealPlan) return;

    try {
      const weekRecipes = currentMealPlan.mealEntries
        .filter((entry) => {
          const entryDate = new Date(entry.date || '');
          return entryDate >= startDate && entryDate <= addDays(startDate, 6);
        })
        .map((entry) => {
          const recipe = recipes.find((r) => r.id === entry.recipeId);
          if (recipe) {
            return {
              recipe,
              portions: entry.portions || recipe.servings // Use entry portions if set, otherwise recipe default
            };
          }
          return null;
        })
        .filter(Boolean);

      for (const item of weekRecipes) {
        if (!item) continue;
        await addRecipeIngredientsManually(
          item.recipe,
          currentUser.defaultOrganizationId,
          currentMealPlan.id,
          item.portions
        );
      }

      onOpenGroceryList();
    } catch (error) {
      console.error('Error adding week to grocery list:', error);
    }
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <div className="space-y-6">
        {/* Recipe Grid */}
        <RecipeGrid
          recipes={recipes}
          onQuickAdd={onQuickAdd}
          droppableId="recipe-grid"
        />

        {/* List View */}
        <MealPlanSectionList />

        {/* Meal Plan Header */}
        <div className="flex items-center justify-between bg-white p-4 rounded-lg shadow-sm">
          <button
            onClick={handlePreviousWeek}
            className="p-2 hover:bg-gray-100 rounded-full transition-colors"
          >
            <ChevronLeft className="h-5 w-5" />
          </button>
          <div className="flex items-center space-x-4">
            <h3 className="text-base font-medium text-menioo-navy">
              Semaine du {format(startDate, 'd MMM yyyy', { locale: fr })}
            </h3>
            {!isAutomationEnabled && (
              <button
                onClick={handleAddWeekToGroceryList}
                className="flex items-center space-x-2 text-menioo-green hover:text-menioo-green-dark transition-colors"
                title="Add week to grocery list"
              >
                <ShoppingBasket className="h-5 w-5" />
                <span className="text-sm">Ajouter la semaine</span>
              </button>
            )}
          </div>
          <button
            onClick={handleNextWeek}
            className="p-2 hover:bg-gray-100 rounded-full transition-colors"
          >
            <ChevronRight className="h-5 w-5" />
          </button>
        </div>

        {/* Calendar Part */}
        <div className="flex flex-col space-y-4">
          {weekDays.map((date) => (
            <MealSectionCalendar
              key={date.toISOString()}
              date={date}
              recipes={recipes}
              mealEntries={currentMealPlan?.mealEntries || []}
              onQuickAdd={onQuickAdd}
              isAutomationEnabled={isAutomationEnabled}
              onOpenGroceryList={onOpenGroceryList}
              handleAddWeekToGroceryList={handleAddWeekToGroceryList}
            />
          ))}
        </div>
      </div>
    </DragDropContext>
  );
}