import React, { useState } from 'react';
import { X, Search } from 'lucide-react';
import { Recipe } from '../../types';
import { ModalRecipeCard } from './ModalRecipeCard';

interface RecipeSelectionModalProps {
  recipes: Recipe[];
  onClose: () => void;
  onSelectRecipe: (recipe: Recipe) => void;
}

export function RecipeSelectionModal({ recipes, onClose, onSelectRecipe }: RecipeSelectionModalProps) {
  const [searchQuery, setSearchQuery] = useState('');
  
  const filteredRecipes = recipes.filter(recipe =>
    recipe.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg w-full max-w-2xl max-h-[90vh] overflow-hidden">
        <div className="p-4 border-b flex justify-between items-center">
          <h3 className="text-lg font-medium text-menioo-navy">Sélectionner une recette</h3>
          <button 
            onClick={onClose} 
            className="text-gray-400 hover:text-gray-500 transition-colors"
          >
            <X className="h-5 w-5" />
          </button>
        </div>

        <div className="p-4 border-b">
          <div className="relative">
            <Search className="absolute left-3 top-1/2 -translate-y-1/2 h-5 w-5 text-gray-400" />
            <input
              type="text"
              placeholder="Rechercher une recette..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-md focus:ring-menioo-green focus:border-menioo-green"
            />
          </div>
        </div>

        <div className="p-4 grid grid-cols-1 sm:grid-cols-2 gap-4 max-h-[60vh] overflow-y-auto">
          {filteredRecipes.map((recipe) => (
            <ModalRecipeCard
              key={recipe.id}
              recipe={recipe}
              onClick={() => onSelectRecipe(recipe)}
            />
          ))}
          {filteredRecipes.length === 0 && (
            <div className="col-span-2 text-center py-8 text-gray-500">
              Aucune recette trouvée
            </div>
          )}
        </div>
      </div>
    </div>
  );
}