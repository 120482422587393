import React, { useMemo } from 'react';
import { useGroceryListStore } from '../../store/useGroceryListStore';
import { PrintHeader } from './PrintHeader';
import { CategorySection } from './CategorySection';
import { useTranslation } from 'react-i18next';
import { useStore } from '../../store/useStore';
import { useCookbookStore } from '../../store/useCookbookStore';

interface AggregatedItem {
  ids: string[];
  name: string;
  quantity: number;
  unit: string;
  category: string;
  checked: boolean;
  mealEntryIds: string[];
  recipeIds: string[];
}

export function PrintableGroceryList() {
  const { t } = useTranslation();
  const { currentList, toggleItem } = useGroceryListStore();
  const recipes = useStore((state) => state.recipes);
  const publicRecipes = useStore((state) => state.publicRecipes);
  const publicRecipeIds = useCookbookStore((state) => state.publicRecipeIds);

  // Get all available recipes
  const allRecipes = useMemo(() => {
    return [
      ...recipes,
      ...publicRecipes.filter(recipe => publicRecipeIds.includes(recipe.id))
    ];
  }, [recipes, publicRecipes, publicRecipeIds]);
  
      const categoryColors: Record<string, string> = {
        produce: '#77DD77',
        meat: '#FF6961',
        seafood: '#779ECB',
        deli: '#FFB347',
        dairy: '#AEC6CF',
        sweetGrocery: '#FFB6C1',
        savoryGrocery: '#C9C97E',
        bakery: '#D2B48C',
        frozen: '#B0E0E6',
        beverages: '#66CCCC',
        condiments: '#FDFD96',
        other: '#D3D3D3', // Fallback for uncategorized items
      };
  // Aggregate items by name, unit, and recipe
  const aggregatedItems = useMemo(() => {
    if (!currentList?.items) return [];

    const aggregateMap = new Map<string, AggregatedItem>();

    currentList.items.forEach(item => {
      const key = `${item.name}-${item.unit}`;
      
      if (aggregateMap.has(key)) {
        const existingItem = aggregateMap.get(key)!;
        existingItem.quantity += item.quantity;
        existingItem.ids.push(item.id);
        existingItem.checked = existingItem.checked && item.checked;
        
        if (item.mealEntryId) {
          existingItem.mealEntryIds.push(item.mealEntryId);
        }
        
        if (item.fromRecipeId) {
          existingItem.recipeIds.push(item.fromRecipeId);
        }
      } else {
        aggregateMap.set(key, {
          ids: [item.id],
          name: item.name,
          quantity: item.quantity,
          unit: item.unit,
          category: item.category,
          checked: item.checked,
          mealEntryIds: item.mealEntryId ? [item.mealEntryId] : [],
          recipeIds: item.fromRecipeId ? [item.fromRecipeId] : []
        });
      }
    });

    return Array.from(aggregateMap.values());
  }, [currentList?.items]);

  // Group items by category
  const groupedItems = useMemo(() => {
    return aggregatedItems.reduce((acc, item) => {
      if (!acc[item.category]) {
        acc[item.category] = [];
      }
      acc[item.category].push(item);
      return acc;
    }, {} as Record<string, typeof aggregatedItems>);
  }, [aggregatedItems]);

  // Modified toggle function to handle aggregated items
  const handleToggleItem = async (ids: string[]) => {
    for (const id of ids) {
      await toggleItem(id);
    }
  };

  if (!currentList) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <p className="text-gray-500">No active grocery list</p>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 pb-16">
      <PrintHeader />
      
      <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="space-y-8">
          {Object.entries(groupedItems).map(([category, items]) => (
          <CategorySection
            key={category}
            category={category}
            items={items}
            color={categoryColors[category] || categoryColors.Default} // Pass the color
            onToggleItem={handleToggleItem}
            recipes={allRecipes}
          />
          ))}
        </div>
      </div>
    </div>
  );
}