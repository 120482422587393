// src/components/organization/OrganizationSwitcher.tsx

import React, { useState, useEffect } from 'react';
import { Home, ChevronDown, Settings, LogOut, Check } from 'lucide-react';
import { useAuthStore } from '../../store/useAuthStore';
import { Link, useNavigate } from 'react-router-dom';
import type { Organization } from '../../types';

export function OrganizationSwitcher() {
  const [isOpen, setIsOpen] = useState(false);
  const [isSwitching, setIsSwitching] = useState(false);
  const navigate = useNavigate();
  const currentUser = useAuthStore((state) => state.currentUser);
  const currentOrganization = useAuthStore((state) => state.currentOrganization);
  const switchOrganization = useAuthStore((state) => state.switchOrganization);
  const logout = useAuthStore((state) => state.logout);
  const [organizationsData, setOrganizationsData] = useState<Record<string, Organization>>({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchOrganizationsData = async () => {
      if (!currentUser) return;
      
      setIsLoading(true);
      setError(null);
      try {
        const orgIds = Object.keys(currentUser.organizations || {});
        const orgDataPromises = orgIds.map(async (orgId) => {
          if (orgId === currentOrganization?.id) {
            return [orgId, currentOrganization];
          }
          try {
            const orgData = await useAuthStore.getState().fetchOrganization(orgId);
            return [orgId, orgData];
          } catch (error) {
            console.error(`Error fetching org ${orgId}:`, error);
            return [orgId, null];
          }
        });

        const orgDataEntries = await Promise.all(orgDataPromises);
        const orgDataMap = Object.fromEntries(
          orgDataEntries.filter(([_, org]) => org !== null)
        );
        
        setOrganizationsData(orgDataMap);
      } catch (error) {
        console.error('Error fetching organizations data:', error);
        setError('Erreur lors du chargement des organisations');
      } finally {
        setIsLoading(false);
      }
    };

    fetchOrganizationsData();
  }, [currentUser, currentOrganization]);

  if (!currentUser || !currentOrganization) {
    return null;
  }

  const userOrganizations = Object.entries(currentUser.organizations || {}).map(([orgId]) => {
    const org = organizationsData[orgId];
    if (!org) {
      return {
        id: orgId,
        name: 'Chargement...',
        role: 'Chargement...',
        isDefault: orgId === currentUser.defaultOrganizationId
      };
    }
    
    const member = org.members?.find(m => m.userId === currentUser.id);
    const roleFromUserOrg = currentUser.organizations[orgId]?.role;
    
    return {
      id: orgId,
      name: org.name,
      role: member?.role || roleFromUserOrg || 'Inconnu',
      isDefault: orgId === currentUser.defaultOrganizationId
    };
  });

const handleSwitchOrg = async (orgId: string) => {
  setIsSwitching(true);
  setError(null);
  try {
    await switchOrganization(orgId);
    setIsOpen(false);
  } catch (error) {
    const errorMessage = error instanceof Error ? error.message : 'Erreur lors du changement d\'organisation';
    setError(errorMessage);
    console.error('Error switching organization:', error);
  } finally {
    setIsSwitching(false);
  }
};

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/login');
    } catch (error) {
      console.error('Error logging out:', error);
      setError('Erreur lors de la déconnexion');
    }
  };

  return (
    <div className="relative">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center space-x-2 px-3 py-2 rounded-lg hover:bg-gray-100 transition-colors"
        disabled={isSwitching}
      >
        <Home className="w-8 h-8 text-menioo-green" />
        <span className="text-sm font-medium text-menioo-navy">
          {currentOrganization.name}
        </span>
        <ChevronDown className={`w-4 h-4 text-gray-400 transition-transform ${isOpen ? 'rotate-180' : ''}`} />
      </button>

      {isOpen && (
        <>
          <div 
            className="fixed inset-0 z-40"
            onClick={() => setIsOpen(false)}
          />
          <div className="absolute right-0 mt-2 w-64 bg-white rounded-lg shadow-lg border border-gray-100 py-2 z-50">
            {/* User info */}
            <div className="px-4 py-2 border-b border-gray-100">
              <div className="text-sm font-medium text-menioo-navy">
                {currentUser.firstName} {currentUser.lastName}
              </div>
              <div className="text-xs text-gray-500">{currentUser.email}</div>
            </div>

            {/* Organizations */}
            <div className="py-2 border-b border-gray-100">
              <div className="px-4 py-1 text-xs font-medium text-gray-500 uppercase">
                Organisations
              </div>
              {isLoading ? (
                <div className="px-4 py-2 animate-pulse space-y-2">
                  <div className="h-4 bg-gray-200 rounded w-3/4"></div>
                  <div className="h-3 bg-gray-200 rounded w-1/2"></div>
                </div>
              ) : (
                userOrganizations.map(({ id, name, role, isDefault }) => (
                  <button
                    key={id}
                    onClick={() => handleSwitchOrg(id)}
                    disabled={isSwitching}
                    className={`w-full px-4 py-2 text-left flex items-center justify-between hover:bg-gray-50 transition-colors ${
                      isSwitching ? 'opacity-50 cursor-not-allowed' : ''
                    }`}
                  >
                    <div className="flex items-center space-x-2">
                      <Home className="w-6 h-6 text-menioo-green" />
                      <div>
                        <div className="text-sm text-menioo-navy">{name}</div>
                        <div className="text-xs text-gray-500 capitalize">{role}</div>
                      </div>
                    </div>
                    {isDefault && (
                      <Check className="w-4 h-4 text-menioo-green" />
                    )}
                  </button>
                ))
              )}
              {error && (
                <div className="px-4 py-2 text-sm text-red-600">
                  {error}
                </div>
              )}
            </div>

            {/* Settings and Sign Out */}
            <div className="py-2">
              <Link
                to="/organization"
                className="w-full px-4 py-2 text-left flex items-center space-x-2 hover:bg-gray-50 transition-colors"
                onClick={() => setIsOpen(false)}
              >
                <Settings className="w-4 h-4 text-gray-500" />
                <span className="text-sm text-menioo-navy">Paramètres</span>
              </Link>
              <button
                onClick={handleLogout}
                disabled={isSwitching}
                className="w-full px-4 py-2 text-left flex items-center space-x-2 text-red-600 hover:bg-gray-50 transition-colors"
              >
                <LogOut className="w-4 h-4" />
                <span className="text-sm">Déconnexion</span>
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
