//IngredientSelector.tsx

import React, { useState, useEffect, useRef, forwardRef } from 'react';
import { Search } from 'lucide-react';
import { StandardIngredient } from '../../types/ingredients';
import { IngredientSearchInput } from './IngredientSearchInput';
import { useTranslation } from 'react-i18next';

interface IngredientSelectorProps {
  value: {
    name: string;
    unit: string;
  };
  onChange: (ingredient: StandardIngredient) => void;
  className?: string;
}


export const IngredientSelector = forwardRef<HTMLDivElement, IngredientSelectorProps>(
  ({ value, onChange, className = '' }, ref) => {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const containerRef = useRef<HTMLDivElement>(null);
    const searchInputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
      const handleClickOutside = (event: MouseEvent) => {
        if (isOpen && containerRef.current && !containerRef.current.contains(event.target as Node)) {
          setIsOpen(false);
        }
      };

      document.addEventListener('mousedown', handleClickOutside);
      return () => document.removeEventListener('mousedown', handleClickOutside);
    }, [isOpen]);

    useEffect(() => {
      if (isOpen && searchInputRef.current) {
        searchInputRef.current.focus();
      }
    }, [isOpen]);

    return (
      <div ref={ref} className={`relative ingredient-selector ${className}`}>
        <div
          onClick={() => setIsOpen(!isOpen)}
          className="ingredient-search-div h-10 flex items-center w-full cursor-pointer bg-white border border-gray-300 rounded-md shadow-sm px-3 text-left focus:outline-none focus:ring-1 focus:ring-menioo-green focus:border-menioo-green transition-colors"
        >
          <Search className="h-4 w-4 text-gray-400 mr-2 flex-shrink-0" />
          <span className={`truncate ${value.name ? 'text-gray-900' : 'text-gray-500'}`}>
            {value.name || t('Select ingredient...')}
          </span>
        </div>

        {isOpen && (
          <div 
            className="fixed z-[100] mt-1 w-full bg-white rounded-md shadow-lg border border-gray-200"
            style={{
              position: 'absolute',
              left: '0',
              minWidth: '100%',
              maxHeight: '400px',
              overflowY: 'auto'
            }}
          >
            <IngredientSearchInput
              ref={searchInputRef}
              onSelect={(ingredient) => {
                onChange(ingredient);
                setIsOpen(false);
              }}
              onClose={() => setIsOpen(false)}
            />
          </div>
        )}
      </div>
    );
  }
);
