import { create } from 'zustand';
import { 
  collection, 
  doc, 
  query, 
  where, 
  orderBy, 
  limit,
  updateDoc,
  addDoc,
  getDocs,
  onSnapshot,
  serverTimestamp 
} from 'firebase/firestore';
import { db } from '../lib/firebase';
import { format, startOfWeek, endOfWeek, addWeeks } from 'date-fns';
import { fr } from 'date-fns/locale';
import { useAuthStore } from './useAuthStore';
import type { Recipe } from '../types';

interface MealPlan {
  id: string;
  name: string;
  organizationId: string;
  status: 'active' | 'archived';
  createdAt: any;
  createdBy: string;
  updatedAt: any;
  updatedBy: string;
  startDate: string;
  endDate: string;
  mealEntries: Array<{
    id: string;
    recipeId: string;
    recipeName: string;
    date?: string;
    mealType?: string;
    portions?: number;
    notes?: string;
    completed?: boolean;
  }>;
  totalMeals: number;
  uniqueRecipes: number;
  automationExpiration?: string | null;
}

interface MealPlanManagerStore {
  currentMealPlan: MealPlan | null;
  previousMealPlans: MealPlan[];
  isLoading: boolean;
  error: string | null;
  createNewMealPlan: (name?: string, automationEnabled?: boolean) => Promise<void>;
  archiveCurrentMealPlan: () => Promise<void>;
  fetchMealPlans: () => Promise<void>;
  updateMealPlan: (id: string, updates: Partial<MealPlan>) => Promise<void>;
  addRecipeToCurrentPlan: (recipe: Recipe, date: string, mealType: string) => Promise<void>;
  initializeSubscription: () => () => void;
}

export const useMealPlanManagerStore = create<MealPlanManagerStore>((set, get) => ({
  currentMealPlan: null,
  previousMealPlans: [],
  isLoading: false,
  error: null,

  initializeSubscription: () => {
    const currentUser = useAuthStore.getState().currentUser;
    if (!currentUser?.defaultOrganizationId) return () => {};

    // Subscribe to active meal plan
    const activePlanQuery = query(
      collection(db, 'newMealPlans'),
      where('organizationId', '==', currentUser.defaultOrganizationId),
      where('status', '==', 'active'),
      orderBy('createdAt', 'desc'),
      limit(1)
    );

    const unsubscribeActive = onSnapshot(activePlanQuery, (snapshot) => {
      if (!snapshot.empty) {
        const currentPlan = {
          id: snapshot.docs[0].id,
          ...snapshot.docs[0].data()
        } as MealPlan;
        set({ currentMealPlan: currentPlan });
      } else {
        set({ currentMealPlan: null });
      }
    });

    // Subscribe to archived meal plans
    const archivedPlansQuery = query(
      collection(db, 'newMealPlans'),
      where('organizationId', '==', currentUser.defaultOrganizationId),
      where('status', '==', 'archived'),
      orderBy('createdAt', 'desc')
    );

    const unsubscribeArchived = onSnapshot(archivedPlansQuery, (snapshot) => {
      const previousPlans = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      })) as MealPlan[];
      set({ previousMealPlans: previousPlans });
    });

    return () => {
      unsubscribeActive();
      unsubscribeArchived();
    };
  },

  createNewMealPlan: async (name?: string, automationEnabled?: boolean) => {
    const currentUser = useAuthStore.getState().currentUser;
    if (!currentUser?.defaultOrganizationId) return;

    set({ isLoading: true, error: null });

    try {
      const startDate = startOfWeek(new Date(), { locale: fr });
      const endDate = endOfWeek(addWeeks(new Date(), 1), { locale: fr });
      const defaultName = `Menu du ${format(startDate, 'd MMMM', { locale: fr })}`;

      // Set automation expiration if enabled
      const automationExpiration = automationEnabled
        ? new Date(Date.now() + 24 * 60 * 60 * 1000).toISOString()
        : null;

      const mealPlanData = {
        organizationId: currentUser.defaultOrganizationId,
        name: name || defaultName,
        status: 'active',
        createdAt: serverTimestamp(),
        createdBy: currentUser.id,
        updatedAt: serverTimestamp(),
        updatedBy: currentUser.id,
        startDate: format(startDate, 'yyyy-MM-dd'),
        endDate: format(endDate, 'yyyy-MM-dd'),
        mealEntries: [],
        totalMeals: 0,
        uniqueRecipes: 0,
        automationExpiration // Add automation expiration
      };

      const mealPlanRef = await addDoc(collection(db, 'newMealPlans'), mealPlanData);

      // Create associated grocery list
      const groceryListData = {
        name: `Liste de courses du ${format(startDate, 'd MMMM', { locale: fr })}`,
        organizationId: currentUser.defaultOrganizationId,
        mealPlanId: mealPlanRef.id,
        status: 'active',
        items: [],
        startDate: format(startDate, 'yyyy-MM-dd'),
        endDate: format(endDate, 'yyyy-MM-dd'),
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
      };

      await addDoc(collection(db, 'groceryLists'), groceryListData);
    } catch (error) {
      console.error('Error creating new meal plan:', error);
      set({ error: (error as Error).message });
      throw error;
    } finally {
      set({ isLoading: false });
    }
  },

  archiveCurrentMealPlan: async () => {
    const { currentMealPlan } = get();
    if (!currentMealPlan) return;

    try {
      await updateDoc(doc(db, 'newMealPlans', currentMealPlan.id), {
        status: 'archived',
        updatedAt: serverTimestamp(),
        updatedBy: useAuthStore.getState().currentUser?.id,
      });
    } catch (error) {
      console.error('Error archiving meal plan:', error);
      throw error;
    }
  },

  fetchMealPlans: async () => {
    const currentUser = useAuthStore.getState().currentUser;
    if (!currentUser?.defaultOrganizationId) return;

    set({ isLoading: true, error: null });

    try {
      const activeQuery = query(
        collection(db, 'newMealPlans'),
        where('organizationId', '==', currentUser.defaultOrganizationId),
        where('status', '==', 'active'),
        orderBy('createdAt', 'desc'),
        limit(1)
      );

      const activeSnapshot = await getDocs(activeQuery);
      const currentMealPlan = activeSnapshot.empty
        ? null
        : {
            id: activeSnapshot.docs[0].id,
            ...activeSnapshot.docs[0].data(),
          } as MealPlan;

      const archivedQuery = query(
        collection(db, 'newMealPlans'),
        where('organizationId', '==', currentUser.defaultOrganizationId),
        where('status', '==', 'archived'),
        orderBy('createdAt', 'desc')
      );

      const archivedSnapshot = await getDocs(archivedQuery);
      const previousMealPlans = archivedSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      })) as MealPlan[];

      set({ currentMealPlan, previousMealPlans, error: null });
    } catch (error) {
      console.error('Error fetching meal plans:', error);
      set({ error: (error as Error).message });
    } finally {
      set({ isLoading: false });
    }
  },

  updateMealPlan: async (id, updates) => {
    set({ isLoading: true, error: null });

    try {
      await updateDoc(doc(db, 'newMealPlans', id), {
        ...updates,
        updatedAt: serverTimestamp(),
        updatedBy: useAuthStore.getState().currentUser?.id,
      });

      if (get().currentMealPlan?.id === id) {
        set(state => ({
          currentMealPlan: state.currentMealPlan
            ? { ...state.currentMealPlan, ...updates }
            : null,
        }));
      }
    } catch (error) {
      console.error('Error updating meal plan:', error);
      set({ error: (error as Error).message });
      throw error;
    } finally {
      set({ isLoading: false });
    }
  },

  addRecipeToCurrentPlan: async (recipe, date, mealType) => {
    const { currentMealPlan } = get();
    if (!currentMealPlan) throw new Error('No active meal plan');

    const currentUser = useAuthStore.getState().currentUser;
    if (!currentUser) throw new Error('User not authenticated');

    try {
      const newEntry = {
        id: crypto.randomUUID(),
        recipeId: recipe.id,
        recipeName: recipe.name,
        date,
        mealType,
        portions: recipe.servings,
        completed: false,
      };

      const updatedEntries = [...currentMealPlan.mealEntries, newEntry];
      const uniqueRecipes = new Set(updatedEntries.map(entry => entry.recipeId)).size;

      await updateDoc(doc(db, 'newMealPlans', currentMealPlan.id), {
        mealEntries: updatedEntries,
        totalMeals: updatedEntries.length,
        uniqueRecipes,
        updatedAt: serverTimestamp(),
        updatedBy: currentUser.id,
      });

      set(state => ({
        currentMealPlan: state.currentMealPlan
          ? { ...state.currentMealPlan, mealEntries: updatedEntries, totalMeals: updatedEntries.length, uniqueRecipes }
          : null,
      }));
    } catch (error) {
      console.error('Error adding recipe to meal plan:', error);
      throw error;
    }
  },
}));