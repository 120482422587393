import React from 'react';
import { CalendarPlus } from 'lucide-react';
import { AnimatedIcon } from './AnimatedIcon';

interface PlannerButtonProps {
  onClick: (e: React.MouseEvent) => void;
  className?: string;
}

export function PlannerButton({ onClick, className = '' }: PlannerButtonProps) {
  const [isSuccess, setIsSuccess] = React.useState(false);

  const handleClick = async (e: React.MouseEvent) => {
    try {
      setIsSuccess(true);
      await onClick(e);
      setTimeout(() => setIsSuccess(false), 1000);
    } catch (error) {
      setIsSuccess(false);
    }
  };

  return (
    <AnimatedIcon
      icon={CalendarPlus}
      isActive={isSuccess}
      onClick={handleClick}
      className={className}
      title="Add to meal plan"
    />
  );
}