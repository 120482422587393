import React from 'react';
import { useReducedMotion } from '../../hooks/useReducedMotion';
import { LucideIcon } from 'lucide-react';

interface AnimatedIconProps {
  icon: LucideIcon;
  isActive?: boolean;
  onClick: (e: React.MouseEvent) => void;
  className?: string;
  activeColor?: string;
  title?: string;
}

export function AnimatedIcon({
  icon: Icon,
  isActive = false,
  onClick,
  className = '',
  activeColor = 'text-menioo-green',
  title
}: AnimatedIconProps) {
  const prefersReducedMotion = useReducedMotion();
  const [isPressed, setIsPressed] = React.useState(false);

  const handleClick = (e: React.MouseEvent) => {
    setIsPressed(true);
    onClick(e);
    setTimeout(() => setIsPressed(false), 200);
  };

  const baseClasses = 'transition-all duration-200 ease-in-out';
  const animationClasses = prefersReducedMotion ? '' : `
    hover:scale-110
    active:scale-95
    ${isPressed ? 'scale-90' : ''}
    ${isActive ? 'animate-bounce-subtle' : ''}
  `;
  const colorClasses = isActive ? activeColor : 'text-gray-500 hover:text-gray-700';

  return (
    <button
      onClick={handleClick}
      className={`${baseClasses} ${animationClasses} ${colorClasses} ${className}`}
      title={title}
    >
      <Icon className={`h-4 w-4 ${isPressed ? 'filter drop-shadow-lg' : ''}`} />
    </button>
  );
}