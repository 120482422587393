import React from 'react';
import { X } from 'lucide-react';

interface ConfirmationDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  onCancel: () => void;
}

export function ConfirmationDialog({ isOpen, onClose, onConfirm, onCancel }: ConfirmationDialogProps) {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-[80]">
      <div className="bg-white rounded-xl w-full max-w-md overflow-hidden shadow-xl transform transition-all">
        <div className="p-6">
          <div className="flex items-center justify-between mb-4">
            <h3 className="text-lg font-semibold text-menioo-navy">
              Activer l'automatisation
            </h3>
            <button
              onClick={onClose}
              className="text-gray-400 hover:text-gray-500 transition-colors"
            >
              <X className="h-5 w-5" />
            </button>
          </div>

          <div className="mb-6">
            <p className="text-gray-600">
              Un menu et une liste de courses sont déjà en cours. Que souhaitez-vous faire ?
            </p>
          </div>

          <div className="flex flex-col space-y-3">
            <button
              onClick={onConfirm}
              className="w-full px-4 py-2 bg-menioo-green text-white rounded-md hover:bg-menioo-green-dark transition-colors"
            >
              Continuer avec le menu actuel
            </button>
            <button
              onClick={onCancel}
              className="w-full px-4 py-2 bg-white text-menioo-navy border border-gray-300 rounded-md hover:bg-gray-50 transition-colors"
            >
              Créer un nouveau menu
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}