import React from 'react';
import { X } from 'lucide-react';
import { Recipe, MealType } from '../../types';
import { ModalRecipeCard } from './ModalRecipeCard';

interface MealTypeModalProps {
  recipe: Recipe;
  onClose: () => void;
  onSelect: (mealType: MealType) => void;
  onSkip?: () => void;
  isOpen: boolean;
}

const MEAL_TYPES = [
  { type: 'breakfast' as MealType, label: 'Petit-déjeuner', icon: '🍳' },
  { type: 'lunch' as MealType, label: 'Déjeuner', icon: '🍝' },
  { type: 'snack' as MealType, label: 'Goûter', icon: '🍪' },
  { type: 'dinner' as MealType, label: 'Dîner', icon: '🍖' }
];

export function MealTypeModal({ recipe, onClose, onSelect, onSkip, isOpen }: MealTypeModalProps) {
  if (!isOpen || !recipe) return null;

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-[60]">
      <div className="bg-white rounded-lg w-full max-w-lg">
        <div className="p-4 border-b flex justify-between items-center">
          <h3 className="text-lg font-medium text-menioo-navy">Type de repas (Optionnel)</h3>
          <button 
            onClick={onClose} 
            className="text-gray-400 hover:text-gray-500 transition-colors"
          >
            <X className="h-5 w-5" />
          </button>
        </div>

        <div className="p-4">
          <div className="mb-6">
            <ModalRecipeCard recipe={recipe} />
          </div>

          <div className="grid grid-cols-2 gap-2 mb-4">
            {MEAL_TYPES.map(({ type, label, icon }) => (
              <button
                key={type}
                onClick={() => onSelect(type)}
                className="p-4 text-center bg-white border border-gray-200 rounded-lg hover:border-menioo-green hover:bg-menioo-green/5 transition-colors"
              >
                <span className="text-2xl mb-2 block">{icon}</span>
                <span className="font-medium text-sm text-menioo-navy">{label}</span>
              </button>
            ))}
          </div>
          {onSkip && (
            <button
              onClick={onSkip}
              className="w-full py-3 px-4 text-gray-600 border border-gray-200 rounded-md hover:bg-gray-50 hover:border-gray-300 transition-colors text-sm font-medium"
            >
              Passer
            </button>
          )}

        </div>
      </div>
    </div>
  );
}
