import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { ChefHat, Menu, X } from 'lucide-react';
import { useAuthStore } from '../../store/useAuthStore';
import { OrganizationSwitcher } from '../organization/OrganizationSwitcher';

export function MainNavigation() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);
  const currentUser = useAuthStore((state) => state.currentUser);
  const currentOrganization = useAuthStore((state) => state.currentOrganization);

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
  const closeMenu = () => setIsMenuOpen(false);

  return (
    <nav className="bg-white shadow-sm">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center h-16">
          {/* Left side: Mobile Menu Button + Logo + Organization Switcher */}
          <div className="flex items-center justify-between flex-1 md:justify-start md:space-x-2">
            {/* Mobile Menu Button */}
            <div className="md:hidden">
              <button
                onClick={toggleMenu}
                className="text-menioo-navy hover:text-menioo-green transition-colors p-2"
                aria-expanded={isMenuOpen}
                aria-label="Toggle menu"
              >
                {isMenuOpen ? (
                  <X className="h-6 w-6" />
                ) : (
                  <Menu className="h-6 w-6" />
                )}
              </button>
            </div>
        
            {/* Logo */}
            <Link to="/" className="flex items-center space-x-2" onClick={closeMenu}>
              <ChefHat className="h-8 w-8 text-menioo-green" />
              <span className="text-2xl font-display font-bold text-menioo-navy">
                Menioo
              </span>
            </Link>
        
     {/* Organization Switcher - Mobile (moved to the right) */}
            <div className="md:hidden ml-auto">
              {currentUser && currentOrganization && <OrganizationSwitcher />}
            </div>
        
            {/* Desktop Navigation Links */}
            <div className="hidden md:flex md:ml-8 space-x-4">
              <Link
                to="/recipes"
                className="text-menioo-navy hover:text-menioo-green transition-colors px-3 py-2"
              >
                Explorer
              </Link>
              
              {isAuthenticated && (
                <>
                  <Link
                    to="/cookbook"
                    className="text-menioo-navy hover:text-menioo-green transition-colors px-3 py-2"
                  >
                    Mes recettes
                  </Link>
                  <Link
                    to="/planning-v2"
                    className="text-menioo-navy hover:text-menioo-green transition-colors px-3 py-2"
                  >
                    Planning
                  </Link>
                   <Link
                    to="/grocery-list"
                    className="text-menioo-navy hover:text-menioo-green transition-colors px-3 py-2"
                  >
                    Liste de course
                  </Link>
                </>
              )}
            </div>
          </div>

          {/* Desktop Auth/Organization Controls */}
          <div className="hidden md:flex md:items-center md:space-x-4">
            {isAuthenticated ? (
              currentUser && currentOrganization && <OrganizationSwitcher />
            ) : (
              <>
                <Link
                  to="/login"
                  className="text-menioo-navy hover:text-menioo-green transition-colors"
                >
                  Connexion
                </Link>
                <Link
                  to="/signup"
                  className="bg-menioo-green text-white px-4 py-2 rounded-md hover:bg-menioo-green-dark transition-colors"
                >
                  Inscription
                </Link>
              </>
            )}
          </div>
        </div>
      </div>

      {/* Mobile Menu */}
      <div className={`md:hidden ${isMenuOpen ? 'block' : 'hidden'}`}>
        <div className="px-2 pt-2 pb-3 space-y-1 bg-white border-t border-gray-100">
          <Link
            to="/recipes"
            className="block px-3 py-2 text-menioo-navy hover:text-menioo-green transition-colors"
            onClick={closeMenu}
          >
            Explorer
          </Link>
          
          {isAuthenticated ? (
            <>
              <Link
                to="/cookbook"
                className="block px-3 py-2 text-menioo-navy hover:text-menioo-green transition-colors"
                onClick={closeMenu}
              >
                Mes recettes
              </Link>
              <Link
                to="/planning-v2"
                className="block px-3 py-2 text-menioo-navy hover:text-menioo-green transition-colors"
                onClick={closeMenu}
              >
                Planning
              </Link>
              <Link
                to="/grocery-list"
                className="block px-3 py-2 text-menioo-navy hover:text-menioo-green transition-colors"
                onClick={closeMenu}
              >
                Liste de course
              </Link>
          
            </>
          ) : (
            <div className="px-3 py-2 space-y-2">
              <Link
                to="/login"
                className="block text-menioo-navy hover:text-menioo-green transition-colors"
                onClick={closeMenu}
              >
                Connexion
              </Link>
              <Link
                to="/signup"
                className="block w-full text-center bg-menioo-green text-white px-4 py-2 rounded-md hover:bg-menioo-green-dark transition-colors"
                onClick={closeMenu}
              >
                Inscription
              </Link>
            </div>
          )}
        </div>
      </div>
    </nav>
  );
}
