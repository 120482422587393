import type { CreateContact } from 'node-mailjet';

const MAILJET_API_KEY = import.meta.env.VITE_MAILJET_API_KEY;
const MAILJET_SECRET_KEY = import.meta.env.VITE_MAILJET_SECRET_KEY;

export const sendVerificationEmail = async (email: string, name: string, verificationLink: string) => {
  try {
    const response = await fetch('https://api.mailjet.com/v3.1/send', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Basic ${btoa(`${MAILJET_API_KEY}:${MAILJET_SECRET_KEY}`)}`
      },
      body: JSON.stringify({
        Messages: [
          {
            From: {
              Email: "welcome@menioo.com",
              Name: "Menioo"
            },
            To: [
              {
                Email: email,
                Name: name
              }
            ],
            Subject: "Welcome to Menioo - Verify Your Email",
            TextPart: `Welcome to Menioo!\n\nPlease verify your email by clicking this link: ${verificationLink}`,
            HTMLPart: `
              <!DOCTYPE html>
              <html>
                <head>
                  <style>
                    .button {
                      background-color: #4CAF50;
                      color: white;
                      padding: 12px 24px;
                      text-decoration: none;
                      border-radius: 4px;
                      display: inline-block;
                      font-family: Arial, sans-serif;
                    }
                    .container {
                      max-width: 600px;
                      margin: 0 auto;
                      padding: 20px;
                      font-family: Arial, sans-serif;
                    }
                    .logo {
                      color: #4CAF50;
                      font-size: 24px;
                      font-weight: bold;
                      margin-bottom: 20px;
                    }
                  </style>
                </head>
                <body>
                  <div class="container">
                    <div class="logo">Menioo</div>
                    <h2>Welcome to Menioo!</h2>
                    <p>Thank you for signing up. Please verify your email address to get started.</p>
                    <p style="margin: 24px 0;">
                      <a href="${verificationLink}" class="button">
                        Verify Email
                      </a>
                    </p>
                    <p>Or copy and paste this link in your browser:</p>
                    <p style="color: #666; word-break: break-all;">
                      ${verificationLink}
                    </p>
                    <p style="margin-top: 40px; font-size: 12px; color: #666;">
                      If you didn't create an account with Menioo, please ignore this email.
                    </p>
                  </div>
                </body>
              </html>
            `
          }
        ]
      })
    });

    if (!response.ok) {
      const error = await response.json();
      console.error('Mailjet API error:', error);
      throw new Error(`Failed to send verification email: ${error.message || 'Unknown error'}`);
    }

    const result = await response.json();
    console.log('Mailjet API response:', result);
    
    return result;
  } catch (error) {
    console.error('Error sending verification email:', error);
    // Don't throw the error - we want the signup to continue even if email fails
    return null;
  }
};
export const sendOrganizationInvite = async (
  email: string,
  inviterName: string,
  organizationName: string,
  inviteLink: string
) => {
  try {
    const emailContent = `
      <!DOCTYPE html>
      <html>
        <head>
          <style>
            .button {
              background-color: #4CAF50;
              color: white;
              padding: 12px 24px;
              text-decoration: none;
              border-radius: 4px;
              display: inline-block;
              font-family: Arial, sans-serif;
            }
            .container {
              max-width: 600px;
              margin: 0 auto;
              padding: 20px;
              font-family: Arial, sans-serif;
            }
          </style>
        </head>
        <body>
          <div class="container">
            <h2>Invitation à rejoindre ${organizationName}</h2>
            <p>${inviterName} vous invite à rejoindre leur organisation sur Menioo.</p>
            <p style="margin: 24px 0;">
              <a href="${inviteLink}" class="button">
                Accepter l'invitation
              </a>
            </p>
            <p>Ou copiez ce lien dans votre navigateur:</p>
            <p style="color: #666; word-break: break-all;">
              ${inviteLink}
            </p>
            <p style="margin-top: 40px; font-size: 12px; color: #666;">
              Si vous n'attendiez pas cette invitation, vous pouvez ignorer cet email.
            </p>
          </div>
        </body>
      </html>
    `;

    const plainText = `
      ${inviterName} vous invite à rejoindre ${organizationName} sur Menioo.
      
      Pour accepter l'invitation, cliquez sur ce lien: ${inviteLink}
      
      Si vous n'attendiez pas cette invitation, vous pouvez ignorer cet email.
    `;

    const response = await fetch('https://menioo-backend-production.up.railway.app/api/email/send', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        to: email,
        subject: `Invitation à rejoindre ${organizationName} sur Menioo`,
        html: emailContent,
        text: plainText,
        from: {
          email: "invites@menioo.com",
          name: "Menioo Invitations"
        }
      })
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Failed to send invitation email');
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error sending invitation email:', error);
    throw error instanceof Error ? error : new Error('Failed to send invitation email');
  }
};


