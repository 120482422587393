import React, { useState } from 'react';
import { Mail, Archive, ViewIcon, ChefHat } from 'lucide-react';
import { useGroceryListStore } from '../../store/useGroceryListStore';
import { useStore } from '../../store/useStore';
import { useCookbookStore } from '../../store/useCookbookStore';
import { CategoryView } from '../grocery/CategoryView';
import { RecipeView } from '../grocery/RecipeView';
import { AddCustomItem } from '../grocery/AddCustomItem';

type ViewMode = 'category' | 'recipe';

export function GroceryListSidebar() {
  const [viewMode, setViewMode] = useState<ViewMode>('category');
  const [isLoading, setIsLoading] = useState(false);
  const { currentList, archiveCurrentList, addCustomItem, generateShareToken, toggleItem } = useGroceryListStore();
  const recipes = useStore((state) => state.recipes);
  const publicRecipes = useStore((state) => state.publicRecipes);
  const publicRecipeIds = useCookbookStore((state) => state.publicRecipeIds);

  // Get all available recipes (both private and saved public ones)
  const allRecipes = React.useMemo(() => {
    return [
      ...recipes,
      ...publicRecipes.filter(recipe => publicRecipeIds.includes(recipe.id))
    ];
  }, [recipes, publicRecipes, publicRecipeIds]);

  const handleSendEmail = async () => {
    if (!currentList) {
      alert("No active grocery list!");
      return;
    }

    const recipient = prompt("Enter the recipient's email:");
    if (!recipient) {
      alert("Email not sent. No recipient provided.");
      return;
    }

    setIsLoading(true);
    try {
      const shareToken = await generateShareToken(currentList.id);
      const shareableUrl = `${window.location.origin}/printable-grocery-list/${shareToken}`;

      const emailHTML = `
        <html>
          <head>
            <style>
              body { font-family: Arial, sans-serif; line-height: 1.6; }
              .container { max-width: 600px; margin: 0 auto; padding: 20px; }
              .button {
                display: inline-block;
                padding: 12px 24px;
                background-color: #4CAF50;
                color: white;
                text-decoration: none;
                border-radius: 4px;
                margin: 20px 0;
              }
              .footer { margin-top: 20px; font-size: 12px; color: #666; }
            </style>
          </head>
          <body>
            <div class="container">
              <h2>Your Grocery List from Menioo</h2>
              <p>Hello!</p>
              <p>Someone has shared a grocery list with you. Click the button below to view the list:</p>
              <p>
                <a href="${shareableUrl}" class="button">View Grocery List</a>
              </p>
              <p>Or copy this link:</p>
              <p>${shareableUrl}</p>
              <div class="footer">
                <p>This link will expire in 30 days.</p>
                <p>Sent from Menioo - Your Meal Planning Assistant</p>
              </div>
            </div>
          </body>
        </html>
      `;

      const response = await fetch('https://menioo-backend-production.up.railway.app/api/email/send', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
             to: recipient, // Corrected field
            subject: "Your Grocery List from Menioo",
            html: emailHTML, // Corrected field
            text: "Someone has shared a grocery list with you. Use the link to view the list.", // Added plain text fallback
          }),
      });

      if (!response.ok) {
        throw new Error('Failed to send email');
      }

      alert("Grocery list link sent successfully!");
    } catch (error) {
      console.error("Failed to send email:", error);
      alert('Failed to send the grocery list');
    } finally {
      setIsLoading(false);
    }
  };
const transformedItems = React.useMemo(() => {
    if (!currentList?.items) return [];

    // Group items by name and unit
    const groupedItems = currentList.items.reduce((acc, item) => {
      const key = `${item.name}-${item.unit}`;
      if (!acc[key]) {
        acc[key] = {
          ids: [],
          name: item.name,
          quantity: 0,
          unit: item.unit,
          category: item.category,
          checked: true, // Will be updated below
          recipeIds: new Set(),
          mealEntryIds: new Set()
        };
      }
      
      acc[key].ids.push(item.id);
      acc[key].quantity += item.quantity;
      acc[key].checked = acc[key].checked && item.checked;
      if (item.fromRecipeId) acc[key].recipeIds.add(item.fromRecipeId);
      if (item.mealEntryId) acc[key].mealEntryIds.add(item.mealEntryId);
      
      return acc;
    }, {} as Record<string, any>);

    // Convert grouped items to array and convert Sets to arrays
    return Object.values(groupedItems).map(item => ({
      ...item,
      recipeIds: Array.from(item.recipeIds),
      mealEntryIds: Array.from(item.mealEntryIds)
    }));
  }, [currentList?.items]);
  
    const handleToggleItems = async (ids: string[]) => {
    if (!ids.length) return;
    
    try {
      for (const id of ids) {
        await toggleItem(id);
      }
    } catch (error) {
      console.error('Error toggling items:', error);
    }
  };
  
  return (
    <div className="h-[calc(100vh-64px)] flex flex-col bg-white">
      {/* Header */}
      <div className="sticky top-0 z-10 flex-shrink-0 p-4 border-b border-gray-200 bg-white">
        <h2 className="text-xl font-display font-bold text-menioo-navy mb-4">
          Liste de courses
        </h2>

        {/* View Toggle */}
        <div className="flex items-center space-x-2">
          <button
            onClick={() => setViewMode('category')}
            className={`px-3 py-1 rounded-md text-sm ${
              viewMode === 'category'
                ? 'bg-menioo-green text-white'
                : 'text-gray-600 hover:bg-gray-100'
            }`}
          >
            <ViewIcon className="h-4 w-4 inline-block mr-1" />
            Catégories
          </button>
          <button
            onClick={() => setViewMode('recipe')}
            className={`px-3 py-1 rounded-md text-sm ${
              viewMode === 'recipe'
                ? 'bg-menioo-green text-white'
                : 'text-gray-600 hover:bg-gray-100'
            }`}
          >
            <ChefHat className="h-4 w-4 inline-block mr-1" />
            Recettes
          </button>
        </div>
      </div>

      {/* Add Custom Item Section */}
      <div className="sticky top-[104px] z-10 flex-shrink-0 p-4 border-b border-gray-200 bg-white">
        <AddCustomItem onAdd={addCustomItem} />
      </div>

      {/* Main Content */}
  <div className="flex-1 overflow-y-auto">
        {!currentList ? (
          <div className="p-4 text-center text-gray-500">
            No active grocery list
          </div>
        ) : viewMode === 'category' ? (
          <CategoryView 
            items={transformedItems} 
            recipes={allRecipes} 
            onToggleItem={handleToggleItems}
          />
        ) : (
          <RecipeView 
            items={transformedItems} 
            recipes={allRecipes} 
            onToggleItem={handleToggleItems}
          />
        )}
      </div>

      {/* Footer Actions */}
      <div className="sticky bottom-0 z-10 flex-shrink-0 p-4 border-t border-gray-200 bg-white">
        <div className="space-y-2">
          <button
            onClick={handleSendEmail}
            disabled={isLoading || !currentList}
            className="w-full flex items-center justify-center px-4 py-2 text-sm font-medium text-gray-700 bg-white rounded-lg shadow-sm border border-gray-200 hover:bg-gray-50 disabled:opacity-50"
          >
            <Mail className="h-4 w-4 mr-2" />
            Send Email
          </button>
          <button
            onClick={() => archiveCurrentList()}
            disabled={isLoading || !currentList}
            className="w-full flex items-center justify-center px-4 py-2 text-sm font-medium text-gray-700 bg-white rounded-lg shadow-sm border border-gray-200 hover:bg-gray-50 disabled:opacity-50"
          >
            <Archive className="h-4 w-4 mr-2" />
            Archive List
          </button>
        </div>
      </div>
    </div>
  );
}
