import { create } from 'zustand';
import { 
  collection,
  doc,
  addDoc,
  deleteDoc,
  query,
  where,
  onSnapshot,
  updateDoc,
  getDocs,
  getDoc,
  writeBatch,
  serverTimestamp,
  orderBy,
  limit
} from 'firebase/firestore';
import { db } from '../lib/firebase';
import { GroceryItem } from '../types';

interface GroceryListStore {
  currentList: {
    id: string;
    items: GroceryItem[];
    organizationId: string;
    status: 'active' | 'archived';
    startDate: string;
    endDate: string;
    createdAt: string;
    updatedAt: string;
  } | null;
  isLoading: boolean;
  error: string | null;
  addCustomItem: (item: Omit<GroceryItem, 'id' | 'checked' | 'createdAt' | 'updatedAt'>) => Promise<void>;
  updateCurrentList: (items: GroceryItem[]) => Promise<void>;
  toggleItem: (itemId: string) => Promise<void>;
  archiveCurrentList: () => Promise<void>;
  initializeSubscription: () => () => void;
  generateShareToken: (listId: string, expiryDays?: number) => Promise<string>;
  getSharedList: (shareToken: string) => Promise<any>;
}

export const useGroceryListStore = create<GroceryListStore>((set, get) => ({
  currentList: null,
  isLoading: false,
  error: null,

  addCustomItem: async (item) => {
  const { currentList } = get();
  if (!currentList) return;

  set({ isLoading: true, error: null });

  try {
    const newItem = {
      id: crypto.randomUUID(),
      name: item.name,
      quantity: Number(item.quantity),
      unit: item.unit,
      category: item.category,
      standardIngredientId: item.standardIngredientId || '',
      checked: false,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString()
    };

    const updatedItems = [...currentList.items, newItem];

    await updateDoc(doc(db, 'groceryLists', currentList.id), {
      items: updatedItems,
      updatedAt: serverTimestamp()
    });
  } catch (error) {
    console.error('Error adding custom item:', error);
    set({ error: (error as Error).message });
    throw error;
  } finally {
    set({ isLoading: false });
  }
},

  updateCurrentList: async (items) => {
    const { currentList } = get();
    if (!currentList) return;

    set({ isLoading: true, error: null });

    try {
      await updateDoc(doc(db, 'groceryLists', currentList.id), {
        items,
        updatedAt: serverTimestamp()
      });
    } catch (error) {
      console.error('Error updating grocery list:', error);
      set({ error: (error as Error).message });
    } finally {
      set({ isLoading: false });
    }
  },

  toggleItem: async (itemId) => {
    const { currentList } = get();
    if (!currentList) return;

    try {
      const updatedItems = currentList.items.map(item =>
        item.id === itemId
          ? { ...item, checked: !item.checked, updatedAt: new Date().toISOString() }
          : item
      );

      await updateDoc(doc(db, 'groceryLists', currentList.id), {
        items: updatedItems,
        updatedAt: serverTimestamp()
      });
    } catch (error) {
      console.error('Error toggling item:', error);
      set({ error: (error as Error).message });
    }
  },

  archiveCurrentList: async () => {
    const { currentList } = get();
    if (!currentList) return;

    set({ isLoading: true, error: null });

    try {
      await updateDoc(doc(db, 'groceryLists', currentList.id), {
        status: 'archived',
        updatedAt: serverTimestamp()
      });
    } catch (error) {
      console.error('Error archiving list:', error);
      set({ error: (error as Error).message });
    } finally {
      set({ isLoading: false });
    }
  },

  initializeSubscription: () => {
    const currentListQuery = query(
      collection(db, 'groceryLists'),
      where('status', '==', 'active'),
      orderBy('createdAt', 'desc'),
      limit(1)
    );

    const unsubscribe = onSnapshot(currentListQuery,
      (snapshot) => {
        if (!snapshot.empty) {
          const list = snapshot.docs[0].data();
          set({ 
            currentList: { 
              ...list, 
              id: snapshot.docs[0].id 
            } as GroceryListStore['currentList'],
            error: null 
          });
        } else {
          set({ currentList: null });
        }
      },
      (error) => {
        console.error('Error in grocery list subscription:', error);
        set({ error: error.message });
      }
    );

    return unsubscribe;
  },

  generateShareToken: async (listId, expiryDays = 30) => {
    try {
      const listRef = doc(db, 'groceryLists', listId);
      const listDoc = await getDoc(listRef);
      
      if (!listDoc.exists()) {
        throw new Error('List not found');
      }

      const listData = listDoc.data();

      if (listData.shareToken && listData.shareExpiry) {
        const expiry = new Date(listData.shareExpiry);
        if (expiry > new Date()) {
          return listData.shareToken;
        }
      }

      const shareToken = crypto.randomUUID();
      const expiry = new Date();
      expiry.setDate(expiry.getDate() + expiryDays);

      await updateDoc(listRef, {
        shareToken,
        shareExpiry: expiry.toISOString(),
        isPublic: true,
        updatedAt: serverTimestamp()
      });

      return shareToken;
    } catch (error) {
      console.error('Error generating share token:', error);
      throw error;
    }
  },

  getSharedList: async (shareToken) => {
    try {
      const q = query(
        collection(db, 'groceryLists'),
        where('shareToken', '==', shareToken),
        where('isPublic', '==', true),
        limit(1)
      );
      
      const snapshot = await getDocs(q);
      if (snapshot.empty) return null;
      
      const list = snapshot.docs[0].data();
      const expiry = new Date(list.shareExpiry);
      
      if (expiry < new Date()) return null;
      
      return {
        ...list,
        id: snapshot.docs[0].id
      };
    } catch (error) {
      console.error('Error fetching shared list:', error);
      throw error;
    }
  }
}));
