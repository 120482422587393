// src/components/planning/ListView/MealPlanHeader.tsx
import React from 'react';
import { ChevronLeft, ChevronRight, Plus } from 'lucide-react';
import { useMealPlanManagerStore } from '../../store/useMealPlanManagerStore';

interface MealPlanHeaderProps {
  onCreateNew: () => void;
  onNavigatePrevious: () => void;
  onNavigateNext: () => void;
  canNavigatePrevious: boolean;
  canNavigateNext: boolean;
  currentIndex: number; // Add this prop
}

export function MealPlanHeader({
  onCreateNew,
  onNavigatePrevious,
  onNavigateNext,
  canNavigatePrevious,
  canNavigateNext,
  currentIndex // Use the prop
}: MealPlanHeaderProps) {
  const currentMealPlan = useMealPlanManagerStore((state) => state.currentMealPlan);
  const previousMealPlans = useMealPlanManagerStore((state) => state.previousMealPlans);

  // Get the currently displayed meal plan based on navigation index
  const displayedMealPlan = currentIndex === 0 
    ? currentMealPlan 
    : previousMealPlans[currentIndex - 1];

  return (
    <div className="flex items-center justify-between bg-white p-4 rounded-lg shadow-sm mb-4">
      <div className="flex items-center space-x-4">
        <button
          onClick={onNavigatePrevious}
          disabled={!canNavigatePrevious}
          className="p-2 hover:bg-gray-100 rounded-full transition-colors disabled:opacity-50"
        >
          <ChevronLeft className="h-5 w-5" />
        </button>
        
        <h3 className="text-base font-medium text-menioo-navy">
          {displayedMealPlan?.name || 'Aucun menu actif'}
        </h3>
        
        <button
          onClick={onNavigateNext}
          disabled={!canNavigateNext}
          className="p-2 hover:bg-gray-100 rounded-full transition-colors disabled:opacity-50"
        >
          <ChevronRight className="h-5 w-5" />
        </button>
      </div>

      <button
        onClick={onCreateNew}
        className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-menioo-green hover:bg-menioo-green-dark"
      >
        <Plus className="h-4 w-4 mr-2" />
        Nouveau Menu
      </button>
    </div>
  );
}
