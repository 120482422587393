import React, { useState } from 'react';
import { Clock, Users, Trash2, Pencil } from 'lucide-react';
import { Link } from 'react-router-dom';
import { Recipe } from '../../types';
import { useCookbookStore } from '../../store/useCookbookStore';
import { useAuthStore } from '../../store/useAuthStore';
import { PlannerButton } from './PlannerButton';
import { HeartButton } from './HeartButton';
import { addRecipeToMealPlanAndGrocery } from '../../utils/mealPlanUtils';

interface RecipeCardProps {
  recipe: Recipe;
  canEdit: boolean;
  isDiscoveryPage?: boolean;
  onDelete: (id: string) => Promise<void>;
}

export function RecipeCard({ recipe, canEdit, isDiscoveryPage = false, onDelete }: RecipeCardProps) {
  const [isDeleting, setIsDeleting] = useState(false);
  const [deleteError, setDeleteError] = useState<string | null>(null);
  const [plannerError, setPlannerError] = useState<string | null>(null);

  // Subscribe to publicRecipeIds and check if this recipe is in the cookbook
  const isInCookbook = useCookbookStore(
    (state) => state.publicRecipeIds.includes(recipe.id)
  );
  const addToCookbook = useCookbookStore((state) => state.addToCookbook);
  const removeFromCookbook = useCookbookStore((state) => state.removeFromCookbook);
  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);
  const currentUser = useAuthStore((state) => state.currentUser);
  const isOwnRecipe = () => {
  if (!currentUser || !currentUser.defaultOrganizationId) return false;
  return recipe.organizationId === currentUser.defaultOrganizationId;
};

  const handleDelete = async (e: React.MouseEvent) => {
    e.preventDefault();
    if (!window.confirm('Are you sure you want to delete this recipe?')) {
      return;
    }

    setIsDeleting(true);
    setDeleteError(null);

    try {
      await onDelete(recipe.id);
    } catch (error) {
      setDeleteError('Failed to delete recipe');
    } finally {
      setIsDeleting(false);
    }
  };

const handleAddToMealPlan = async (e: React.MouseEvent) => {
  e.preventDefault();
  setPlannerError(null);

  if (!currentUser?.defaultOrganizationId) {
    setPlannerError('Please log in to add recipes to your meal plan');
    return;
  }

  try {
    // Add recipe to meal plan
    await addRecipeToMealPlanAndGrocery(
      recipe,
      '',
      '',
      currentUser.id,
      currentUser.defaultOrganizationId
    );

    // Only add to cookbook if recipe is from a different organization
    if (!isInCookbook && recipe.organizationId !== currentUser.defaultOrganizationId) {
      await addToCookbook(recipe.id);
    }
  } catch (error) {
    console.error('Error adding to meal plan or cookbook:', error);
    setPlannerError('Failed to add recipe to meal plan and cookbook');
    throw error;
  }
};


  const handleToggleBookmark = async (e: React.MouseEvent) => {
    e.preventDefault();
    try {
      if (isInCookbook) {
        await removeFromCookbook(recipe.id);
      } else {
        await addToCookbook(recipe.id);
      }
    } catch (error) {
      console.error('Error toggling bookmark:', error);
    }
  };

  return (
    <div className="relative group rounded-xl overflow-hidden shadow-sm hover:shadow-md transition-all duration-200">
      {(deleteError || plannerError) && (
        <div className="absolute top-0 left-0 right-0 bg-red-50 text-red-600 text-sm px-4 py-2 z-10">
          {deleteError || plannerError}
        </div>
      )}

      <Link to={`/recipes/${recipe.id}`} className="block relative aspect-[4/3]">
        {recipe.image ? (
          <img
            src={recipe.image}
            alt={recipe.name}
            className="w-full h-full object-cover"
          />
        ) : (
          <div className="w-full h-full bg-gray-200 flex items-center justify-center">
            <span className="text-gray-400">No image</span>
          </div>
        )}

        {/* Dark gradient overlay */}
        <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent" />

        {/* Recipe info overlay */}
        <div className="absolute inset-x-0 bottom-0 p-4 text-white">
          <h3 className="text-lg font-medium leading-tight mb-2 line-clamp-2">
            {recipe.name}
          </h3>
          <div className="flex items-center space-x-4 text-sm">
            <div className="flex items-center">
              <Clock className="h-4 w-4 mr-1" />
              <span>{recipe.prepTime + recipe.cookTime} mins</span>
            </div>
            <div className="flex items-center">
              <Users className="h-4 w-4 mr-1" />
              <span>{recipe.servings}</span>
            </div>
          </div>
        </div>

        {/* Action buttons */}
        <div className="absolute top-2 right-2 flex items-center space-x-2">
          {isAuthenticated && (
            <PlannerButton
              onClick={handleAddToMealPlan}
              className="bg-white/90 backdrop-blur-sm p-2 rounded-full shadow-md hover:bg-white transition-colors"
            />
          )}
          {canEdit && (
            <>
              <Link
                to={`/recipes/${recipe.id}/edit`}
                className="bg-white/90 backdrop-blur-sm p-2 rounded-full shadow-md hover:bg-white transition-colors"
              >
                <Pencil className="h-5 w-5 text-menioo-navy" />
              </Link>
              <button
                onClick={handleDelete}
                disabled={isDeleting}
                className="bg-white/90 backdrop-blur-sm p-2 rounded-full shadow-md hover:bg-white transition-colors"
              >
                <Trash2 className="h-5 w-5 text-red-500" />
              </button>
            </>
          )}
        </div>

        {/* Heart button */}
        {isAuthenticated && !isOwnRecipe() && (
    // In src/components/recipe/RecipeCard.tsx
          <HeartButton
            recipeId={recipe.id}
            isActive={isInCookbook}
            onClick={handleToggleBookmark}
            className="absolute top-2 left-2"
            initialCount={recipe.bookmarkCount || 0}
          />
        )}
      </Link>
    </div>
  );
}
