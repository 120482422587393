import { Recipe } from '../types';
import { collection, addDoc, doc, getDocs, query, where, serverTimestamp, updateDoc, getDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { GroceryList, GroceryListItem } from '../types/grocery';

async function handleGroceryList(
  organizationId: string,
  newItems: GroceryListItem[],
  newMealPlanId: string
): Promise<void> {
  const groceryListQuery = query(
    collection(db, 'groceryLists'),
    where('organizationId', '==', organizationId),
    where('status', '==', 'active')
  );

  const groceryListSnapshot = await getDocs(groceryListQuery);
  const existingList = groceryListSnapshot.empty ? null : {
    id: groceryListSnapshot.docs[0].id,
    ...groceryListSnapshot.docs[0].data()
  } as GroceryList;

  if (existingList) {
    // Always update existing list with new items
    const updatedItems = [...existingList.items, ...newItems];
    await updateDoc(doc(db, 'groceryLists', existingList.id), {
      mealPlanId: existingList.mealPlanId || newMealPlanId,
      items: updatedItems,
      updatedAt: serverTimestamp()
    });
  } else {
    // Create new list only if none exists
    const startDate = new Date();
    const endDate = new Date();
    endDate.setDate(endDate.getDate() + 6);

    const newList = {
      name: `Grocery List ${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}`,
      organizationId,
      mealPlanId: newMealPlanId,
      status: 'active' as const,
      items: newItems,
      startDate: startDate.toISOString().split('T')[0],
      endDate: endDate.toISOString().split('T')[0],
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp()
    };

    await addDoc(collection(db, 'groceryLists'), newList);
  }
}

export async function addRecipeToMealPlan(
  recipe: Recipe,
  date: string | null,
  mealType: string | null,
  userId: string,
  organizationId: string,
  portions?: number
): Promise<void> {
  try {
    if (!recipe?.id || !userId || !organizationId) {
      throw new Error('Missing required fields');
    }

    // Get current meal plan first
    const mealPlanQuery = query(
      collection(db, 'newMealPlans'),
      where('organizationId', '==', organizationId),
      where('status', '==', 'active')
    );

    const mealPlanSnapshot = await getDocs(mealPlanQuery);
    if (!mealPlanSnapshot.empty) {
      const currentPlan = mealPlanSnapshot.docs[0];
      const currentEntries = currentPlan.data().mealEntries || [];
      
      // Create new meal entry
      const mealEntry = {
        id: crypto.randomUUID(),
        recipeId: recipe.id,
        recipeName: recipe.name,
        recipeImage: recipe.image,
        date: date,
        mealType: mealType,
        portions: portions || recipe.servings
      };

      // Update meal plan with new entry
      await updateDoc(doc(db, 'newMealPlans', currentPlan.id), {
        mealEntries: [...currentEntries, mealEntry],
        totalMeals: currentEntries.length + 1,
        uniqueRecipes: new Set([...currentEntries.map(e => e.recipeId), recipe.id]).size,
        updatedAt: serverTimestamp(),
        updatedBy: userId
      });
    }
  } catch (error) {
    console.error('Error adding recipe to meal plan:', error);
    throw error;
  }
}


export async function addRecipeToMealPlanAndGrocery(
  recipe: Recipe,
  date: string,
  mealType: string,
  userId: string,
  organizationId: string,
  portions?: number
): Promise<void> {
  try {
    if (!recipe?.id || !userId || !organizationId) {
      throw new Error('Missing required fields');
    }

    // Get organization data to get number of persons
    const orgDoc = await getDoc(doc(db, 'organizations', organizationId));
    if (!orgDoc.exists()) {
      throw new Error('Organization not found');
    }
    const orgData = orgDoc.data();
    const defaultPortions = orgData.numberOfPersons || recipe.servings;

    // Get current meal plan first
    const mealPlanQuery = query(
      collection(db, 'newMealPlans'),
      where('organizationId', '==', organizationId),
      where('status', '==', 'active')
    );

    const mealPlanSnapshot = await getDocs(mealPlanQuery);
    if (!mealPlanSnapshot.empty) {
      const currentPlan = mealPlanSnapshot.docs[0];
      const currentPlanData = currentPlan.data();
      const currentEntries = currentPlanData.mealEntries || [];
      
      // Create new meal entry
      const mealEntryId = crypto.randomUUID();
      const mealEntry = {
        id: mealEntryId,
        recipeId: recipe.id,
        recipeName: recipe.name,
        recipeImage: null,
        date: date || null,
        mealType: mealType || null,
        portions: portions || defaultPortions, // Use organization's number of persons as default
        completed: false
      };

      // Update meal plan with new entry
      await updateDoc(doc(db, 'newMealPlans', currentPlan.id), {
        mealEntries: [...currentEntries, mealEntry],
        totalMeals: currentEntries.length + 1,
        uniqueRecipes: new Set([...currentEntries.map(e => e.recipeId), recipe.id]).size,
        updatedAt: serverTimestamp(),
        updatedBy: userId
      });

      // Check if automation is enabled
      const isAutomationEnabled = currentPlanData.automationExpiration && 
        new Date(currentPlanData.automationExpiration) > new Date();

      // Only add to grocery list if automation is enabled
      if (isAutomationEnabled) {
        // Calculate portion multiplier for grocery items
        const portionMultiplier = (portions || defaultPortions) / recipe.servings;

        // Create new grocery items
        const newItems: GroceryListItem[] = recipe.ingredients.map(ingredient => ({
          id: crypto.randomUUID(),
          name: ingredient.name,
          quantity: ingredient.quantity * portionMultiplier,
          unit: ingredient.unit,
          category: ingredient.category,
          standardIngredientId: ingredient.standardIngredientId || '',
          fromRecipeId: recipe.id,
          mealEntryId: mealEntryId,
          checked: false,
          addedAt: new Date().toISOString()
        }));

        // Handle grocery list update
        await handleGroceryList(organizationId, newItems, currentPlan.id);
      }
    }
  } catch (error) {
    console.error('Error adding to meal plan and grocery list:', error);
    throw error;
  }
}


// New function for manually adding recipe ingredients to grocery list
export async function addRecipeIngredientsManually(
  recipe: Recipe,
  organizationId: string,
  mealPlanId: string,
  portions?: number
): Promise<void> {
  try {
    if (!recipe?.id || !organizationId || !mealPlanId) {
      throw new Error('Missing required fields');
    }

    const portionMultiplier = portions ? portions / recipe.servings : 1;

    const newItems: GroceryListItem[] = recipe.ingredients.map(ingredient => ({
      id: crypto.randomUUID(),
      name: ingredient.name,
      quantity: ingredient.quantity * portionMultiplier,
      unit: ingredient.unit,
      category: ingredient.category,
      standardIngredientId: ingredient.standardIngredientId || '',
      fromRecipeId: recipe.id,
      checked: false,
      addedAt: new Date().toISOString()
    }));

    await handleGroceryList(organizationId, newItems, mealPlanId);
  } catch (error) {
    console.error('Error adding recipe ingredients to grocery list:', error);
    throw error;
  }
}

export async function removeIngredientsByMealEntry(mealEntryId: string, organizationId: string): Promise<void> {
  try {
    // Get active grocery list
    const groceryListQuery = query(
      collection(db, 'groceryLists'),
      where('organizationId', '==', organizationId),
      where('status', '==', 'active')
    );

    const groceryListSnapshot = await getDocs(groceryListQuery);
    if (!groceryListSnapshot.empty) {
      const currentList = groceryListSnapshot.docs[0];
      const currentItems = currentList.data().items || [];

      // Filter out items associated with the meal entry
      const updatedItems = currentItems.filter(
        (item: GroceryListItem) => item.mealEntryId !== mealEntryId
      );

      // Update grocery list with filtered items
      await updateDoc(doc(db, 'groceryLists', currentList.id), {
        items: updatedItems,
        updatedAt: serverTimestamp()
      });
    }
  } catch (error) {
    console.error('Error removing ingredients:', error);
    throw error;
  }
}

export async function updateIngredientPortions(
  mealEntryId: string, 
  organizationId: string,
  newPortions: number,
  originalPortions: number
): Promise<void> {
  try {
    // Get active grocery list
    const groceryListQuery = query(
      collection(db, 'groceryLists'),
      where('organizationId', '==', organizationId),
      where('status', '==', 'active')
    );

    const groceryListSnapshot = await getDocs(groceryListQuery);
    if (!groceryListSnapshot.empty) {
      const currentList = groceryListSnapshot.docs[0];
      const currentItems = currentList.data().items || [];

      // Calculate portion multiplier
      const multiplier = newPortions / originalPortions;

      // Update quantities for matching items
      const updatedItems = currentItems.map((item: GroceryListItem) => {
        if (item.mealEntryId === mealEntryId) {
          return {
            ...item,
            quantity: item.quantity * multiplier
          };
        }
        return item;
      });

      // Update grocery list with adjusted quantities
      await updateDoc(doc(db, 'groceryLists', currentList.id), {
        items: updatedItems,
        updatedAt: serverTimestamp()
      });
    }
  } catch (error) {
    console.error('Error updating ingredient portions:', error);
    throw error;
  }
}

export async function removeRecipeIngredients(recipeId: string, organizationId: string): Promise<void> {
  try {
    // Remove from meal plan
    const mealPlanQuery = query(
      collection(db, 'newMealPlans'),
      where('organizationId', '==', organizationId),
      where('status', '==', 'active')
    );

    const mealPlanSnapshot = await getDocs(mealPlanQuery);
    if (!mealPlanSnapshot.empty) {
      const currentPlan = mealPlanSnapshot.docs[0];
      const currentEntries = currentPlan.data().mealEntries || [];
      
      await updateDoc(doc(db, 'newMealPlans', currentPlan.id), {
        mealEntries: currentEntries.filter((entry: any) => entry.recipeId !== recipeId),
        updatedAt: serverTimestamp()
      });
    }

    // Remove from grocery list
    const groceryListQuery = query(
      collection(db, 'groceryLists'),
      where('organizationId', '==', organizationId),
      where('status', '==', 'active')
    );

    const groceryListSnapshot = await getDocs(groceryListQuery);
    if (!groceryListSnapshot.empty) {
      const currentList = groceryListSnapshot.docs[0];
      const currentItems = currentList.data().items || [];

      const updatedItems = currentItems.filter(
        (item: GroceryListItem) => item.fromRecipeId !== recipeId
      );

      await updateDoc(doc(db, 'groceryLists', currentList.id), {
        items: updatedItems,
        updatedAt: serverTimestamp()
      });
    }
  } catch (error) {
    console.error('Error removing recipe:', error);
    throw error;
  }
}
export async function removeMealEntry(
  mealEntryId: string,
  mealPlanId: string,
  currentEntries: any[],
  organizationId: string,
  automationExpiration?: string | null
): Promise<void> {
  try {
    // Check if automation is enabled
    const isAutomationEnabled = automationExpiration && 
      new Date(automationExpiration) > new Date();

    // Only remove ingredients if automation is enabled
    if (isAutomationEnabled) {
      await removeIngredientsByMealEntry(mealEntryId, organizationId);
    }

    // Update meal plan entries
    const updatedEntries = currentEntries.filter(entry => entry.id !== mealEntryId);

    await updateDoc(doc(db, 'newMealPlans', mealPlanId), {
      mealEntries: updatedEntries,
      totalMeals: updatedEntries.length,
      uniqueRecipes: new Set(updatedEntries.map(entry => entry.recipeId)).size,
      updatedAt: serverTimestamp()
    });
  } catch (error) {
    console.error('Error removing meal entry:', error);
    throw error;
  }
}
