import React from 'react';
import { Clock, Users } from 'lucide-react';
import { Recipe } from '../../types';

interface ModalRecipeCardProps {
  recipe: Recipe;
  onClick?: () => void;
}

export function ModalRecipeCard({ recipe, onClick }: ModalRecipeCardProps) {
  return (
    <div 
      onClick={onClick}
      className="group relative bg-white rounded-lg shadow-sm border border-gray-100 overflow-hidden hover:shadow-md transition-all duration-200 cursor-pointer"
    >
      <div className="relative aspect-[4/3]">
        {recipe.image ? (
          <img
            src={recipe.image}
            alt={recipe.name}
            className="w-full h-full object-cover"
          />
        ) : (
          <div className="w-full h-full bg-gray-200 flex items-center justify-center">
            <span className="text-gray-400">No image</span>
          </div>
        )}
        <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent" />
        <div className="absolute inset-x-0 bottom-0 p-4 text-white">
          <h3 className="text-lg font-medium leading-tight mb-2 line-clamp-2">
            {recipe.name}
          </h3>
          <div className="flex items-center space-x-4 text-sm">
            <div className="flex items-center">
              <Clock className="h-4 w-4 mr-1" />
              <span>{recipe.prepTime + recipe.cookTime} mins</span>
            </div>
            <div className="flex items-center">
              <Users className="h-4 w-4 mr-1" />
              <span>{recipe.servings}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}