import React from 'react';
import { useTranslation } from 'react-i18next';
import { Check } from 'lucide-react';
import { Recipe } from '../../types';

interface CategoryViewProps {
  items: Array<{
    ids: string[];
    name: string;
    quantity: number;
    unit: string;
    category: string;
    checked: boolean;
    recipeIds?: string[];
  }>;
  recipes: Recipe[];
  onToggleItem: (ids: string[]) => void;
}

export function CategoryView({ items, recipes, onToggleItem }: CategoryViewProps) {
  const { t } = useTranslation();

  // Group items by category
  const groupedItems = React.useMemo(() => {
    return items.reduce((acc, item) => {
      if (!acc[item.category]) {
        acc[item.category] = [];
      }
      acc[item.category].push(item);
      return acc;
    }, {} as Record<string, typeof items>);
  }, [items]);

  // Get recipe names for an item
  const getRecipeNames = (recipeIds: string[] = []) => {
    if (!recipeIds?.length) return '';

    const names = recipeIds
      .map(id => recipes.find(r => r.id === id)?.name)
      .filter(Boolean);

    if (names.length === 0) return '';
    if (names.length === 1) return names[0];
    if (names.length === 2) return `${names[0]} and ${names[1]}`;
    
    return `${names[0]}, ${names[1]} and ${names.length - 2} more`;
  };

  // Format quantity and unit with proper translation
  const formatQuantityAndUnit = (quantity: number, unit: string) => {
    const translatedUnit = t(`units.${unit}`, { defaultValue: unit });
    return `${quantity} ${translatedUnit.toLowerCase()}`;
  };

  return (
    <div className="divide-y divide-gray-100">
      {Object.entries(groupedItems).map(([category, categoryItems]) => (
        <div key={category} className="p-6">
          <h3 className="text-lg font-display font-semibold text-menioo-navy mb-4">
            {t(`categories.${category}`)}
          </h3>
          <ul className="space-y-3">
            {categoryItems.map((item) => {
              // Create a unique key by combining name, unit, and first ID
              const itemKey = `${item.name}-${item.unit}-${item.ids?.[0] || crypto.randomUUID()}`;
              
              return (
                <li key={itemKey} className="flex items-start group">
                  <button
                    onClick={() => onToggleItem(item.ids)}
                    className={`flex-shrink-0 w-5 h-5 mt-1 rounded border-2 mr-3 flex items-center justify-center transition-colors ${
                      item.checked
                        ? 'bg-menioo-green border-menioo-green'
                        : 'border-gray-300 group-hover:border-menioo-green'
                    }`}
                  >
                    {item.checked && <Check className="h-3 w-3 text-white" />}
                  </button>
                  <div className={`flex-1 ${item.checked ? 'line-through text-gray-400' : ''}`}>
                    <div className="text-menioo-navy">
                      <span className="font-medium">
                        {formatQuantityAndUnit(item.quantity, item.unit)}
                      </span>{' '}
                      {item.name}
                    </div>
                    {item.recipeIds?.length > 0 && (
                      <div className="text-xs text-gray-400 mt-0.5">
                        Dans: {getRecipeNames(item.recipeIds)}
                      </div>
                    )}
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      ))}
    </div>
  );
}