import React, { useState, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ViewIcon, Archive, ChefHat, Mail, Printer, Menu } from 'lucide-react';
import { useGroceryListStore } from '../../store/useGroceryListStore';
import { CategoryView } from './CategoryView';
import { RecipeView } from './RecipeView';
import { AddCustomItem } from './AddCustomItem';
import { useStore } from '../../store/useStore';
import { useCookbookStore } from '../../store/useCookbookStore';
import { GroceryItem } from '../../types';

type ViewMode = 'category' | 'recipe';

interface AggregatedItem {
  ids: string[];
  name: string;
  quantity: number;
  unit: string;
  category: string;
  checked: boolean;
  mealEntryIds: string[];
  recipeIds: string[];
  standardIngredientId: string;
  createdAt: string;
  updatedAt: string;
}

export function GroceryListView() {
  const navigate = useNavigate();
  const [viewMode, setViewMode] = useState<ViewMode>('category');
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { currentList, archiveCurrentList, addCustomItem, isLoading, error, toggleItem } = useGroceryListStore();
  const recipes = useStore((state) => state.recipes);
  const publicRecipes = useStore((state) => state.publicRecipes);
  const publicRecipeIds = useCookbookStore((state) => state.publicRecipeIds);

  // Get all available recipes (both private and saved public ones)
  const allRecipes = useMemo(() => {
    return [
      ...recipes,
      ...publicRecipes.filter(recipe => publicRecipeIds.includes(recipe.id))
    ];
  }, [recipes, publicRecipes, publicRecipeIds]);

  // Aggregate items by name, unit, and recipe
  const aggregatedItems = useMemo(() => {
    if (!currentList?.items) return [];

    const aggregateMap = new Map<string, AggregatedItem>();

    currentList.items.forEach(item => {
      // For recipe view, we want to group by recipe and then by item
      const key = `${item.name}-${item.unit}`;
      
      if (aggregateMap.has(key)) {
        const existingItem = aggregateMap.get(key)!;
        existingItem.quantity += item.quantity;
        existingItem.ids.push(item.id);
        existingItem.checked = existingItem.checked && item.checked;
        
        // Add meal entry ID if it exists
        if (item.mealEntryId) {
          existingItem.mealEntryIds.push(item.mealEntryId);
        }
        
        // Add recipe ID if it exists
        if (item.fromRecipeId) {
          existingItem.recipeIds.push(item.fromRecipeId);
        }
      } else {
        aggregateMap.set(key, {
          ids: [item.id],
          name: item.name,
          quantity: item.quantity,
          unit: item.unit,
          category: item.category,
          checked: item.checked,
          mealEntryIds: item.mealEntryId ? [item.mealEntryId] : [],
          recipeIds: item.fromRecipeId ? [item.fromRecipeId] : [],
          standardIngredientId: item.standardIngredientId,
          createdAt: item.createdAt,
          updatedAt: item.updatedAt
        });
      }
    });

    return Array.from(aggregateMap.values());
  }, [currentList?.items]);

  const handleArchive = async () => {
    if (window.confirm('Are you sure you want to archive this grocery list?')) {
      await archiveCurrentList();
    }
  };

  const handlePrintableVersion = () => {
    navigate('/printable-grocery-list');
  };

  // Modified toggle function to handle aggregated items
  const handleToggleItem = async (ids: string[]) => {
    for (const id of ids) {
      await toggleItem(id);
    }
  };

  if (!currentList) {
    return (
      <div className="min-h-[40vh] flex flex-col items-center justify-center bg-white rounded-xl shadow-sm p-8">
        <ChefHat className="h-16 w-16 text-menioo-green/20 mb-4" />
        <p className="text-menioo-navy font-medium">No active grocery list</p>
        <p className="text-gray-500 text-sm mt-2">
          Add recipes to your meal plan to generate a list
        </p>
        <div className="mt-4">
          <AddCustomItem onAdd={addCustomItem} />
        </div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      {/* Header */}
      <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center space-y-4 sm:space-y-0">
        <div className="flex items-center space-x-3">
          <ChefHat className="h-6 w-6 text-menioo-green" />
          <button
            onClick={handlePrintableVersion}
            className="text-2xl font-display font-bold text-menioo-navy hover:text-menioo-green transition-colors"
          >
            Liste de course
          </button>
        </div>

        {/* Actions - Desktop */}
        <div className="hidden sm:flex items-center space-x-4">
          <button
            onClick={handlePrintableVersion}
            className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
          >
            <Printer className="h-4 w-4 mr-2" />
            Version imprimable
          </button>

          <div className="flex items-center bg-white rounded-lg shadow-sm border border-gray-200">
            <button
              onClick={() => setViewMode('category')}
              className={`px-4 py-2 text-sm font-medium rounded-l-lg ${
                viewMode === 'category'
                  ? 'bg-menioo-green text-white'
                  : 'text-gray-700 hover:bg-gray-50'
              }`}
            >
              <ViewIcon className="h-4 w-4 inline-block mr-2" />
              Catégories
            </button>
            <button
              onClick={() => setViewMode('recipe')}
              className={`px-4 py-2 text-sm font-medium rounded-r-lg ${
                viewMode === 'recipe'
                  ? 'bg-menioo-green text-white'
                  : 'text-gray-700 hover:bg-gray-50'
              }`}
            >
              <ChefHat className="h-4 w-4 inline-block mr-2" />
              Recettes
            </button>
          </div>

          <button
            onClick={handleArchive}
            disabled={isLoading}
            className="flex items-center px-4 py-2 text-sm font-medium text-gray-700 bg-white rounded-lg shadow-sm border border-gray-200 hover:bg-gray-50 disabled:opacity-50"
          >
            <Archive className="h-4 w-4 mr-2" />
            Fermer la liste
          </button>
        </div>
      </div>

      {/* Error message */}
      {error && (
        <div className="bg-red-50 border-l-4 border-red-400 p-4 rounded">
          <p className="text-red-700">{error}</p>
        </div>
      )}

      {/* Add Custom Item */}
      <div>
        <AddCustomItem onAdd={addCustomItem} />
      </div>

      {/* List content */}
      <div className="bg-white rounded-xl shadow-sm border border-gray-100 overflow-hidden">
        {viewMode === 'category' ? (
          <CategoryView 
            items={aggregatedItems} 
            recipes={allRecipes} 
            onToggleItem={handleToggleItem}
          />
        ) : (
          <RecipeView 
            items={aggregatedItems} 
            recipes={allRecipes} 
            onToggleItem={handleToggleItem}
          />
        )}
      </div>
    </div>
  );
}