import React, { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import { useGroceryListStore } from '../../store/useGroceryListStore';
import { PrintHeader } from './PrintHeader';
import { CategorySection } from './CategorySection';
import { useStore } from '../../store/useStore';
import { useCookbookStore } from '../../store/useCookbookStore';

interface AggregatedItem {
  ids: string[];
  name: string;
  quantity: number;
  unit: string;
  category: string;
  checked: boolean;
  mealEntryIds: string[];
  recipeIds: string[];
}

export function SharedGroceryList() {
  const { shareToken } = useParams();
  const { getSharedList } = useGroceryListStore();
  const [list, setList] = useState<any>(null);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const recipes = useStore((state) => state.recipes);
  const publicRecipes = useStore((state) => state.publicRecipes);
  const publicRecipeIds = useCookbookStore((state) => state.publicRecipeIds);

  // Get all available recipes
  const allRecipes = useMemo(() => {
    return [
      ...recipes,
      ...publicRecipes.filter(recipe => publicRecipeIds.includes(recipe.id))
    ];
  }, [recipes, publicRecipes, publicRecipeIds]);

      const categoryColors: Record<string, string> = {
        produce: '#77DD77',
        meat: '#FF6961',
        seafood: '#779ECB',
        deli: '#FFB347',
        dairy: '#AEC6CF',
        sweetGrocery: '#FFB6C1',
        savoryGrocery: '#C9C97E',
        bakery: '#D2B48C',
        frozen: '#B0E0E6',
        beverages: '#66CCCC',
        condiments: '#FDFD96',
        other: '#D3D3D3', // Fallback for uncategorized items
      };

  useEffect(() => {
    async function fetchList() {
      if (!shareToken) {
        setError("Liste introuvable");
        setIsLoading(false);
        return;
      }

      try {
        const sharedList = await getSharedList(shareToken);
        if (!sharedList) {
          setError("Liste introuvable");
          return;
        }
        if (sharedList.status === 'archived') {
          setError("La liste a été archivée");
          return;
        }
        setList(sharedList);
      } catch (err) {
        setError("Erreur lors du chargement de la liste");
      } finally {
        setIsLoading(false);
      }
    }
    fetchList();
  }, [shareToken, getSharedList]);

  // Aggregate items by name, unit, and recipe
  const aggregatedItems = useMemo(() => {
    if (!list?.items) return [];

    const aggregateMap = new Map<string, AggregatedItem>();

    list.items.forEach((item: any) => {
      const key = `${item.name}-${item.unit}`;
      
      if (aggregateMap.has(key)) {
        const existingItem = aggregateMap.get(key)!;
        existingItem.quantity += item.quantity;
        existingItem.ids.push(item.id);
        existingItem.checked = existingItem.checked && item.checked;
        
        if (item.mealEntryId) {
          existingItem.mealEntryIds.push(item.mealEntryId);
        }
        
        if (item.fromRecipeId) {
          existingItem.recipeIds.push(item.fromRecipeId);
        }
      } else {
        aggregateMap.set(key, {
          ids: [item.id],
          name: item.name,
          quantity: item.quantity,
          unit: item.unit,
          category: item.category,
          checked: item.checked,
          mealEntryIds: item.mealEntryId ? [item.mealEntryId] : [],
          recipeIds: item.fromRecipeId ? [item.fromRecipeId] : []
        });
      }
    });

    return Array.from(aggregateMap.values());
  }, [list?.items]);

  // Group items by category
  const groupedItems = useMemo(() => {
    return aggregatedItems.reduce((acc, item) => {
      if (!acc[item.category]) {
        acc[item.category] = [];
      }
      acc[item.category].push(item);
      return acc;
    }, {} as Record<string, typeof aggregatedItems>);
  }, [aggregatedItems]);

  const handleToggleItem = async (ids: string[]) => {
    if (!list?.id) return;

    try {
      // Update local state optimistically
      setList(prevList => ({
        ...prevList,
        items: prevList.items.map((item: any) => ({
          ...item,
          checked: ids.includes(item.id) ? !item.checked : item.checked
        }))
      }));

      // Update database
      const listRef = doc(db, 'groceryLists', list.id);
      await updateDoc(listRef, {
        items: list.items.map((item: any) => ({
          ...item,
          checked: ids.includes(item.id) ? !item.checked : item.checked
        })),
        updatedAt: new Date().toISOString()
      });
    } catch (error) {
      console.error('Error updating item:', error);
      // Revert local state on error
      setList(prevList => ({
        ...prevList,
        items: prevList.items.map((item: any) => ({
          ...item,
          checked: !ids.includes(item.id) ? item.checked : !item.checked
        }))
      }));
    }
  };

  if (isLoading) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-menioo-green"></div>
      </div>
    );
  }

  if (error || !list) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <div className="text-center">
          <p className="text-gray-500 mb-2">{error || "Liste introuvable"}</p>
          <a href="/" className="text-menioo-green hover:text-menioo-green-dark">
            Retour à l'accueil
          </a>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 pb-16">
      <PrintHeader />
      
      <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="space-y-8">
      {Object.entries(groupedItems).map(([category, items]) => (
           <CategorySection
              key={category}
              category={category}
              items={items}
              color={categoryColors[category] || categoryColors.Default} // Pass the color
              onToggleItem={handleToggleItem}
              recipes={allRecipes}
            />
          ))}
        </div>
      </div>
    </div>
  );
}