import React, { useState, useEffect, useRef } from 'react';
import { Search, Plus } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { StandardIngredient } from '../../types/ingredients';
import { useIngredientSearchStore } from '../../store/useIngredientSearchStore';

interface GroceryItemSelectorProps {
  value: {
    name: string;
    unit: string;
  };
  onChange: (ingredient: StandardIngredient | { name: string; category: string; preferredUnit: string }) => void;
  className?: string;
}

export function GroceryItemSelector({ value, onChange, className = '' }: GroceryItemSelectorProps) {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [customName, setCustomName] = useState('');
  const containerRef = useRef<HTMLDivElement>(null);
  const {
    searchQuery,
    setSearchQuery,
    getFilteredIngredients,
    initializeIngredients,
  } = useIngredientSearchStore();

  useEffect(() => {
    const unsubscribe = initializeIngredients();
    return () => unsubscribe();
  }, [initializeIngredients]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (isOpen && containerRef.current && !containerRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [isOpen]);

  const filteredIngredients = getFilteredIngredients();

  const handleAddCustom = (name: string) => {
    onChange({
      name,
      category: 'other',
      preferredUnit: 'piece'
    });
    setIsOpen(false);
    setCustomName('');
    setSearchQuery('');
  };

  return (
    <div ref={containerRef} className={`relative ${className}`}>
      <div
        onClick={() => setIsOpen(!isOpen)}
        className="h-10 flex items-center w-full cursor-pointer bg-white border border-gray-300 rounded-md shadow-sm px-3 text-left focus:outline-none focus:ring-1 focus:ring-menioo-green focus:border-menioo-green transition-colors"
      >
        <Search className="h-4 w-4 text-gray-400 mr-2 flex-shrink-0" />
        <span className={`truncate ${value.name ? 'text-gray-900' : 'text-gray-500'}`}>
          {value.name || t('Search or add item...')}
        </span>
      </div>

      {isOpen && (
        <div className="absolute z-10 mt-1 w-full bg-white rounded-md shadow-lg border border-gray-200">
          <div className="p-2">
            <input
              type="text"
              value={searchQuery}
              onChange={(e) => {
                setSearchQuery(e.target.value);
                setCustomName(e.target.value);
              }}
              className="w-full border-gray-300 rounded-md shadow-sm focus:border-menioo-green focus:ring-menioo-green sm:text-sm"
              placeholder={t('Search or type new item name...')}
              autoFocus
            />
          </div>

          <ul className="max-h-60 overflow-y-auto">
            {filteredIngredients.map((ingredient) => (
              <li
                key={ingredient.id}
                onClick={() => {
                  onChange(ingredient);
                  setIsOpen(false);
                  setSearchQuery('');
                }}
                className="px-4 py-2 hover:bg-gray-50 cursor-pointer"
              >
                {ingredient.name}
              </li>
            ))}
          </ul>

          {customName && filteredIngredients.length === 0 && (
            <button
              onClick={() => handleAddCustom(customName)}
              className="w-full px-4 py-2 text-left hover:bg-gray-50 flex items-center text-menioo-green border-t border-gray-100"
            >
              <Plus className="h-4 w-4 mr-2" />
              Add "{customName}"
            </button>
          )}
        </div>
      )}
    </div>
  );
}
