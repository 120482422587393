import React, { useState, useEffect } from 'react';
import { Droppable } from '@hello-pangea/dnd';
import { ShoppingBasket } from 'lucide-react';
import { useMealPlanManagerStore } from '../../store/useMealPlanManagerStore';
import { useAuthStore } from '../../store/useAuthStore';
import { removeIngredientsByMealEntry, addRecipeIngredientsManually } from '../../utils/mealPlanUtils';
import { MealPlanHeader } from './MealPlanHeader';
import { NewMealPlanDialog } from './NewMealPlanDialog';
import { MealEntryCard } from './MealEntryCard';
import { useStore } from '../../store/useStore';

export function MealPlanSectionList() {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const {
    currentMealPlan,
    previousMealPlans,
    createNewMealPlan,
    updateMealPlan,
    initializeSubscription,
  } = useMealPlanManagerStore();
  const currentUser = useAuthStore((state) => state.currentUser);
  const recipes = useStore((state) => state.recipes);
  const publicRecipes = useStore((state) => state.publicRecipes);

  useEffect(() => {
    const unsubscribe = initializeSubscription();
    return () => unsubscribe();
  }, [initializeSubscription]);

  const allMealPlans = [
    ...(currentMealPlan ? [currentMealPlan] : []),
    ...previousMealPlans,
  ];

  const displayedMealPlan = allMealPlans[currentIndex];
  const unplannedMealEntries = displayedMealPlan?.mealEntries.filter((entry) => !entry.date) || [];
  const isAutomationEnabled =
    displayedMealPlan?.automationExpiration &&
    new Date(displayedMealPlan.automationExpiration) > new Date();

  const handleCreateNew = async (name: string) => {
    if (!currentUser?.defaultOrganizationId) return;

    try {
      await createNewMealPlan(name);
      setIsDialogOpen(false);
      setCurrentIndex(0);
    } catch (error) {
      console.error('Error creating new meal plan:', error);
    }
  };

  const handleUpdateEntry = async (entryId: string, updates: any) => {
    if (!displayedMealPlan) return;

    try {
      const updatedEntries = displayedMealPlan.mealEntries.map((entry) =>
        entry.id === entryId ? { ...entry, ...updates } : entry
      );

      await updateMealPlan(displayedMealPlan.id, {
        mealEntries: updatedEntries,
      });
    } catch (error) {
      console.error('Error updating meal entry:', error);
    }
  };

  const handleRemoveEntry = async (entryId: string) => {
    if (!displayedMealPlan || !currentUser?.defaultOrganizationId) return;

    try {
      if (isAutomationEnabled) {
        await removeIngredientsByMealEntry(entryId, currentUser.defaultOrganizationId);
      }

      const updatedEntries = displayedMealPlan.mealEntries.filter((entry) => entry.id !== entryId);
      await updateMealPlan(displayedMealPlan.id, {
        mealEntries: updatedEntries,
      });
    } catch (error) {
      console.error('Error removing meal entry:', error);
    }
  };

  const handleAddToGroceryList = async (entryId: string) => {
    if (!displayedMealPlan || !currentUser?.defaultOrganizationId) return;

    try {
      const entry = displayedMealPlan.mealEntries.find((e) => e.id === entryId);
      if (!entry) return;

      const recipe = [...recipes, ...publicRecipes].find((r) => r.id === entry.recipeId);
      if (!recipe) return;

      await addRecipeIngredientsManually(
        recipe,
        currentUser.defaultOrganizationId,
        displayedMealPlan.id,
        entry.portions
      );
    } catch (error) {
      console.error('Error adding to grocery list:', error);
    }
  };

  const handleAddUnplannedToGroceryList = async () => {
    if (!displayedMealPlan || !currentUser?.defaultOrganizationId) return;

    try {
      for (const entry of unplannedMealEntries) {
        const recipe = [...recipes, ...publicRecipes].find((r) => r.id === entry.recipeId);
        if (recipe) {
          await addRecipeIngredientsManually(
            recipe,
            currentUser.defaultOrganizationId,
            displayedMealPlan.id,
            entry.portions
          );
        }
      }
    } catch (error) {
      console.error('Error adding unplanned recipes to grocery list:', error);
    }
  };

  return (
    <div className="mt-8">
      <MealPlanHeader
        onCreateNew={() => setIsDialogOpen(true)}
        onNavigatePrevious={() => setCurrentIndex((prev) => Math.min(prev + 1, allMealPlans.length - 1))}
        onNavigateNext={() => setCurrentIndex((prev) => Math.max(prev - 1, 0))}
        canNavigatePrevious={currentIndex < allMealPlans.length - 1}
        canNavigateNext={currentIndex > 0}
        currentIndex={currentIndex}
      />

      <div className="flex items-center justify-between mb-4">
        <h3 className="text-lg font-medium text-menioo-navy">Recettes non planifiées</h3>
        {!isAutomationEnabled && (
          <button
            onClick={handleAddUnplannedToGroceryList}
            className="flex items-center space-x-2 text-menioo-green hover:text-menioo-green-dark transition-colors"
          >
            <ShoppingBasket className="h-5 w-5" />
            <span>Ajouter à la liste</span>
          </button>
        )}
      </div>

      <Droppable droppableId="unplanned-recipes">
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            className={`border-2 border-dashed rounded-lg p-4 text-center transition-all ${
              snapshot.isDraggingOver ? 'border-green-500 bg-green-50' : 'border-gray-200'
            }`}
          >
            {unplannedMealEntries.length === 0 && <span className="text-gray-500">Glissez des recettes ici</span>}
            {provided.placeholder}
          </div>
        )}
      </Droppable>

      <div className="space-y-4 mt-4">
        {unplannedMealEntries.map((entry) => (
          <div key={entry.id} >
            <MealEntryCard
              entry={entry}
              onUpdate={(updates) => handleUpdateEntry(entry.id, updates)}
              onRemove={() => handleRemoveEntry(entry.id)}
              isArchived={displayedMealPlan.status === 'archived'}
              isAutomationEnabled={isAutomationEnabled}
            />
          </div>
        ))}
      </div>

      <NewMealPlanDialog
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        onConfirm={handleCreateNew}
      />
    </div>
  );
}
